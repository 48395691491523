import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getSharingInfo, deleteSharingInfo } from "../../services/Permissions";
import {
  Add,
  RemoveCircleOutline
} from "@mui/icons-material";
import { AddSharing } from "./AddSharing";
import { RequestsTab } from "./RequestsTab";

const SharingItem = ({ user, handleClick }: any) => {
  const [deleting, setDeleting] = useState(false);

  const handleItemClick = () => {
    handleClick(!deleting);
    setDeleting(!deleting);
  };

  return (
    <Tooltip title={user.email} followCursor>
      <ListItem disableGutters>
        {!deleting ? (
          <ListItemText
            primary={user.name}
            secondary={user.isCreator && "Proprietário"}
          />
        ) : (
          <ListItemText
            primary={user.name}
            secondary={user.isCreator && "Proprietário"}
            sx={{ textDecoration: "line-through" }}
          />
        )}
        {!user.isCreator && (
          <ListItemIcon sx={{ cursor: "pointer" }} onClick={handleItemClick}>
            {!deleting ? <RemoveCircleOutline /> : <Add />}
          </ListItemIcon>
        )}
      </ListItem>
    </Tooltip>
  );
};

export const Sharing = ({ id, itemType, open, setOpen }: any) => {
  const [item, setItem] = useState<any | null>(null);
  const [usersWithAccess, setUsersWithAccess] = useState<any[] | null>(null);
  const [optionsToAdd, setOptionsToAdd] = useState<any[] | null>(null);
  const [accessRequests, setAccessRequests] = useState<any[] | null>(null);

  const [toDelete, setToDelete] = useState<boolean[]>([]);

  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const updateData = useCallback(() => {
    setLoading(true);

    getSharingInfo(id, itemType)
      .then((res) => {
        setItem(res.item);
        setOptionsToAdd(res.usersAbleToAccess);

        const orderedUsers = res.usersWithAccess
          .filter((user) => user.isCreator)
          .concat(res.usersWithAccess.filter((user) => !user.isCreator));

        setUsersWithAccess(orderedUsers);
        setAccessRequests(res.accessRequests);
        if (!res.accessRequests?.length) {
          setTabIndex(0);
        }

        setToDelete(Array.from(Array(orderedUsers.length), () => false));
      })
      .finally(() => setLoading(false));
  }, [id, itemType]);

  useEffect(() => {
    if (open && !openAdd) updateData();
  }, [open, openAdd, updateData]);

  const handleClose = () => setOpen(false);

  const updateToDelete = (index: number, del: boolean) => {
    toDelete[index] = del;
    setToDelete(toDelete);
  };

  const handleSaveChanges = () => {
    setLoading(true);
    const deleting =
      usersWithAccess?.filter((u, index) => toDelete[index]) || [];
    deleteSharingInfo(id, item, deleting, itemType).then(() => updateData());
    setLoading(false);
  };

  const handleAddUser = () => setOpenAdd(true);

  const handleTabChange = (e: React.SyntheticEvent, newValue: number) =>
    setTabIndex(newValue);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        display="flex"
        width="400px"
        maxHeight="500px"
        flexDirection="column"
        borderRadius={3}
        pb={2}
        mx={2}
      >
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab
            value={0}
            sx={{ fontSize: "16px" }}
            label={<Typography marginTop={0.5}>Compartilhar</Typography>}
          />
          <Tab
            value={1}
            sx={{ fontSize: "16px" }}
            disabled={!accessRequests?.length}
            label={
              <Badge
                badgeContent={accessRequests?.length || 0}
                color={"primary"}
                showZero
              >
                <Typography marginTop={0.5}>Solicitações</Typography>
              </Badge>
            }
          />
        </Tabs>
        <Box hidden={tabIndex !== 0}>
          {loading ? (
            <CircularProgress />
          ) : (
            <List sx={{ pt: 0 }}>
              {usersWithAccess &&
                usersWithAccess.length > 0 &&
                usersWithAccess.map((user, index) => (
                  <SharingItem
                    user={user}
                    key={index}
                    handleClick={(del: boolean) => updateToDelete(index, del)}
                  />
                ))}
            </List>
          )}
          <DialogActions>
            <Button onClick={handleAddUser} disabled={loading}>
              Adicionar usuários
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveChanges}
              disabled={loading}
            >
              Salvar
            </Button>
          </DialogActions>
        </Box>
        <Box hidden={tabIndex !== 1}>
          <RequestsTab
            id={id}
            item={item}
            itemType={itemType}
            accessRequests={accessRequests}
            updateData={updateData}
          />
        </Box>
      </Box>
      <AddSharing
        itemId={item?.id}
        open={openAdd}
        setOpen={setOpenAdd}
        options={optionsToAdd}
      />
    </Dialog>
  );
};
