import { Button, Dialog, Grid, Typography, Box, Input, NativeSelect } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useState } from "react";
import { NewQuestion } from "./question";
import { NewQuestionnaryProps } from "./types";

export const NewQuestionnary = ({ hidden }: NewQuestionnaryProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(false); };

  const handleClickSubmit = () => {
    const videoUrl = "https://www.youtube.com/shorts/uthRkIJHoa8"; // Defina o valor do seu parâmetro
    const url = `http://localhost:3000/obter_valor?videoUrl=${videoUrl}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => console.log(data.resultado))
      .catch((error) => console.error("Erro:", error));
  };

  return (
    <>
      {!hidden && (
        <div>
          <Button variant="contained" onClick={handleClickOpen} disabled>
            <Typography>Novo Questionário</Typography>
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <Box display="flex" width="100%" maxHeight="100%" alignItems="center" flexDirection="column" p={2} pb={1} >
              <Box display="flex" width="100%" maxHeight="100%" alignItems="center" flexDirection="column" border={1} borderColor="lightgray" borderRadius={3} p={3} pb={2} m={1} >
                <Box width="100%" position="relative" display="flex" sx={{ backgroundColor: "white" }} pb={2} >
                  <Grid container display="flex" width="100%">
                    <Box display="flex" width="100%">
                      <Typography width="20%" pt="5px" pr="2%" align="right">Título:</Typography>
                      <Box display="flex" width="80%"><Input fullWidth type="text"></Input></Box>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography width="20%" pt="5px" pr="2%" align="right">Descrição:</Typography>
                      <Box display="flex" width="80%"><Input fullWidth type="text"></Input></Box>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography width="20%" pt="5px" pr="2%" align="right">Tags:</Typography>
                      <Box display="flex" width="80%"><Input fullWidth type="text"></Input></Box>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography width="20%" pt="5px" pr="2%" align="right">Postagem:</Typography>
                      <Box display="flex" width="80%"><Input fullWidth type="datetime-local"></Input></Box>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography width="20%" pt="5px" pr="2%" align="right">Visibilidade:</Typography>
                      <Box display="flex" width="80%">
                        <NativeSelect fullWidth defaultValue={"private"}>
                          <option value={"private"}>Privado</option>
                          <option value={"public"}>Público</option>
                          <option value={"restricted"}>Restrito</option>
                        </NativeSelect>
                      </Box>
                    </Box>
                  </Grid>
                </Box>

                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">Título</TableCell>
                        <TableCell align="right">Descrição</TableCell>
                        <TableCell align="right">Tags</TableCell>
                        <TableCell align="right">Tipo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box display="flex" flexDirection="row" pt="10px" width="100%" sx={{ justifyContent: "space-between" }} >
                  <NewQuestion />
                  <Button sx={{ borderRadius: 6 }} variant="contained" onClick={handleClickSubmit}>{" "}Submeter Questionário{" "}</Button>
                </Box>
              </Box>
            </Box>
          </Dialog>
        </div>
      )}
    </>
  );
};
