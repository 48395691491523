import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { getPages } from "./paths";
import "./app.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const theme = createTheme({
  typography: { button: { textTransform: "none" } },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const pages = await getPages();

root.render(
  <>
    <div className="app">
      <IntlProvider textComponent="span" locale="en" defaultLocale="en">
        <ThemeProvider theme={theme}>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CIENT_ID as string}
          >
            <CssBaseline />
            <BrowserRouter>
              <Routes>
                {pages?.map(({ path, component }: any, index) => (
                  <Route path={path} element={component} key={index} />
                ))}
              </Routes>
            </BrowserRouter>
          </GoogleOAuthProvider>
        </ThemeProvider>
      </IntlProvider>
    </div>
  </>
);
