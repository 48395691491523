import { LoginServices } from "../Login";
import { insertPerformance } from "./Performance";
import { insertEvaluations } from "./Evaluation";
import { insertReports } from "./Report";
import supabase from "./supabaseClient";

export const insertResponse = async (response: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        response["user"] = loggedUser.id;
        const { data, error } = await supabase.from("Response").insert([response]).select("id");
        if (error) {
            console.error("[insertResponse] Error: "+error.message);
            return null;
        } else { return data[0].id; }
    } else { return null; }
};

export const insertResponses = async (responses: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        for (var i = 0; i < responses.length; i++){ responses[i]["user"] = loggedUser.id; }
        const { data, error } = await supabase.from("Response").insert(responses).select("id");
        if (error) { console.error("[insertResponses] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const insertAllResponses = async (performance: any, response: any, evaluation: any, report: any) => {
    var idPerformance = await insertPerformance(performance);
    if (idPerformance !== null){
        for (var i = 0; i < response.length; i++){ response[i]["performance"] = idPerformance; }
        if (evaluation !== undefined){ await insertEvaluations(evaluation); }
        if (response !== undefined){ await insertResponses(response); }
        if (report !== undefined){ await insertReports(report); }
    }

    return idPerformance;
};

export const selectResponses = async () => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        const { data, error } = await supabase.from("Response").select().eq("user", loggedUser.id);
        if (error) { console.error("[selectResponses] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const selectAllResponses = async () => {
    const { data, error } = await supabase.from("Response").select();
    if (error) { console.error("[selectAllResponses] Error: "+error.message); }
    return { data };
};

export const selectResponseById = async (id: any) => {
    const { data, error } = await supabase.from("Response").select().eq("id", id);
    if (error) { console.error("[selectResponseById] Error: "+error.message); }
    return { data };
};

export const selectResponseByVariable = async (variable: string, value: any) => {
    const { data, error } = await supabase.from("Response").select().eq(variable, value);
    if (error) { console.error("[selectResponseByVariable] Error: "+error.message); }
    return { data };
};

export const selectResponsesByQuestionnary = async (questionnary: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        var { data, error } = await supabase.from("Response").select("*, Question(*)")
                                            .eq("Question.questionnary", questionnary)
                                            .eq("user", loggedUser.id);
        if (error) { console.error("[selectResponsesByQuestionnary] Error: "+error.message); }
        if (data !== null){
            for (var i = 0; i < data.length; i ++){ delete data[i]["Question"]; }
        }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const updateResponse = async (response: any, id: any) => {
    const { error } = await supabase.from("Response").update(response).eq("id", id);
    if (error) {
        console.error("[updateResponse] Error: "+error.message);
        return false;
    } else { return true; }
};

export const deleteResponse = async (id: any) => {
    const { error } = await supabase.from("Response").delete().eq("id", id);
    if (error) {
        console.error("[deleteResponse] Error: "+error.message);
        return false;
    } else { return true; }
};