export const encrypt = (value: string) => {
    const CryptoJS = require("crypto-js");
    const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
    return CryptoJS.AES.encrypt(value, secretKey).toString();
}

export const decrypt = (value: string) => {
    const CryptoJS = require("crypto-js");
    const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
    return CryptoJS.AES.decrypt(value, secretKey).toString(CryptoJS.enc.Utf8);
}