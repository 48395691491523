import Box from "@mui/material/Box";
import { AppBar } from "../../components/AppBar";
import { SideBar } from "../../components/SideBar";
import { ItemType, PermissionProps, YardSearch, YardsProps } from "../types";
import { NewYard } from "../../containers/NewCard";
import { NewQuestionnary } from "../../containers/NewQuestionnary";
import { Breadcrumb } from "../../components/Breadcrumb";
import { InviteButton } from "../../components/InviteButton";
import { LoadCards } from "../../services/fetchCards";
import { LinearProgress, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useState } from "react";
import { checkPermissions } from "../../services/Permissions";
import { LoginServices } from "../../services/Login";
import { BlockedAccess } from "../../components/BlockedAccessWarning";

export const Yards = ({ id, Item: item, genericSearch }: YardsProps) => {
  const isGenericYardPage = id === undefined;
  const [loading, setLoading] = useState(true);
  const [editPermission, setEditPermission] = useState<
    PermissionProps | undefined
  >(undefined);

  useEffect(() => {
    if (!isGenericYardPage) {
      checkPermissions(item, undefined, ItemType.Yard)
        .then(setEditPermission)
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [isGenericYardPage, item]);

  const getGenericTitle = () => {
    switch (genericSearch) {
      case YardSearch.Owned:
        return "Meus Yards";
      case YardSearch.Public:
        return "Yards Públicos";
      case YardSearch.Shared:
        return "Yards Compartilhados";
    }
  };

  var createNewYard = isGenericYardPage && LoginServices.getLoggedUser();
  var editYard = editPermission?.edit || editPermission?.share;

  const breadcrumbTitle = (
    <>
      <Typography variant="h5" color="black" pr={1}>
        {isGenericYardPage ? getGenericTitle() : item?.title}
      </Typography>
      {(createNewYard || editYard) && (
        <ArrowDropDownIcon sx={{ color: "black" }} />
      )}
    </>
  );

  var newQuestionnary = <></>;
  if (!isGenericYardPage) {
    newQuestionnary = (
      <>
        <Box pl={2}>
          <NewQuestionnary hidden={!editPermission?.edit} />
        </Box>
      </>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <AppBar />
      <Box display="flex" flexDirection="row">
        <SideBar />
        <Box flexDirection="column" m={3} width="100%">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb={3}
          >
            <Breadcrumb
              title={breadcrumbTitle}
              id={id}
              isYard={true}
              isGenericYard={isGenericYardPage}
              permissions={editPermission}
            />
            <Box display="flex" flexDirection="row">
              <NewYard
                id={id}
                isGenericYardPage={isGenericYardPage}
                hidden={!editPermission?.edit}
              />
              {newQuestionnary}
              <Box pl={2}>
                <InviteButton
                  id={id || ""}
                  itemType={ItemType.Yard}
                  permissions={editPermission}
                />
              </Box>
            </Box>
          </Box>
          {loading && <LinearProgress />}
          {!loading && (isGenericYardPage || editPermission?.view) && (
            <LoadCards
              id={id}
              isYard={isGenericYardPage}
              yardSearch={genericSearch}
              yardPermissions={editPermission}
            />
          )}
          {!loading && !isGenericYardPage && !editPermission?.view && (
            <BlockedAccess itemId={item?.id} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
