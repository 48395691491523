import { Alert, Snackbar } from "@mui/material";
import { CustomAlertProps } from "../types";

export const CustomAlert = ({message, setMessage, severity}: CustomAlertProps) => {
    
    return (
        <Snackbar   autoHideDuration={5000} 
                    open={!!message} 
                    onClose={()=> setMessage("")}
                    onClick={(e) => e.stopPropagation()}
                    anchorOrigin={{vertical: 'bottom', horizontal:'right'}}>
            
            <Alert  sx={{ width: '100%' }} 
                    onClose={()=> setMessage("")} 
                    severity={severity}>

                {message}
            </Alert>
        </Snackbar>
    );
}