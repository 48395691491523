import { useState } from "react";
import { Badge, Button, Dialog, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import DeleteIcon from "@mui/icons-material/Delete";
import { RemoveItem } from "./RemoveItem";
import { CardSettingsProps } from "../Card/types";
import { NewCardDialog } from "../../containers/NewCard/NewCardDialog";
import { Sharing } from "../../containers/Sharing";
import { ItemType } from "../../pages/types";

export const CardSettingsButton = ({
  id,
  isGenericYard,
  permissions,
}: CardSettingsProps) => {
  const [openRemoveDrawer, setRemoveDrawerOpen] = useState(false);
  const [openEditCardDialog, setEditDrawerOpen] = useState(false);
  const [openShareDialog, setShareDialogOpen] = useState(false);

  const handleClickOpen = (text: string) => {
    if (text === "Remover") setRemoveDrawerOpen(true);
    if (text === "Editar") setEditDrawerOpen(true);
    if (text === "Compartilhar") setShareDialogOpen(true);
  };

  const handleClose = () => {
    setRemoveDrawerOpen(false);
    setEditDrawerOpen(false);
    setShareDialogOpen(false);
  };

  const options = [
    { text: "Convidar", icon: <PersonAddAlt1Icon />, hidden: true, badge:false },
    {
      text: "Editar",
      icon: <EditIcon />,
      optionClick: (
        <Dialog open={openEditCardDialog} onClose={handleClose}>
          <NewCardDialog
            id={id}
            isGenericYardPage={isGenericYard}
            handleClose={handleClose}
            isEditCard
            isYard={isGenericYard}
          />
        </Dialog>
      ),
      hidden: !permissions?.edit,
      badge: false,
    },
    {
      text: "Remover",
      icon: <DeleteIcon />,
      optionClick: (
        <Dialog open={openRemoveDrawer} onClose={handleClose}>
          <RemoveItem
            isYard={isGenericYard}
            id={id}
            closeDialog={handleClose}
          />
        </Dialog>
      ),
      hidden: !permissions?.edit,
      badge: false,
    },
    {
      text: "Compartilhar",
      icon: <PersonAddAlt1Icon />,
      optionClick: (
        <Sharing
          open={openShareDialog}
          setOpen={setShareDialogOpen}
          id={id}
          itemType={isGenericYard ? ItemType.Yard : ItemType.Study}
        />
      ),
      hidden: !permissions?.share,
      badge: permissions?.hasRequestsToAnswer
    },
  ];

  return (
    <>
      {options.map(({ text, icon, optionClick, hidden, badge }, index) => {
        return (
          <div key={index}>
            {!hidden && (
              <>
                <Button
                  onClick={() => handleClickOpen(text)}
                  sx={{
                    display: "flex",
                    px: 2,
                    alignItems: "center",
                    color: "black",
                  }}
                  hidden={hidden}
                >
                  <Badge
                  variant="dot"
                  color="error"
                  badgeContent={badge ? 1 : 0}
                  >
                  {icon}
                  </Badge>
                    <Typography sx={{ p: 2 }}>{text}</Typography>
                </Button>
                {optionClick}
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
