import { defineMessages } from "react-intl";

export default defineMessages({
  notLoggedIn: {
    id: "app.components.BlockedAccessWarning.notLoggedIn",
    defaultMessage: "É necessário estar logado para acessar o conteúdo dessa página.",
  },
  noPermission: {
    id: "app.components.BlockedAccessWarning.noPermission",
    defaultMessage: "Clique no botão abaixo para solicitar acesso:",
  }
});