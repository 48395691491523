export enum YardSearch {
  Owned,
  Public,
  Shared
}

export enum ItemType {
  Yard,
  Study,
  Questionnary,
  Content
}

export interface SharingProps {
  profile: string;
  user:number;
}

export interface PermissionProps {
  edit: boolean;
  view : boolean;
  share: boolean;
  alreadyRequestedAccess: boolean;
  hasRequestsToAnswer:boolean;
}

export interface ItemProps {
  id: string;
  sharing?: SharingProps[];
  visibility: string;
  creator: number;
  title: string;
  description?: string;
  tags?: string[];
}

export interface YardsProps {
  Item?: ItemProps;
  id: string | undefined;
  genericSearch: YardSearch;
}

export interface ContentProps {
  id: string;
  Item: ItemProps;
}

export interface StudiesProps {
  id: string;
  Item: ItemProps;
  permissions?: PermissionProps;
  yard?: number;
}
