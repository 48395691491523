import { LoginServices } from "../Login";
import supabase from "./supabaseClient";

export const insertEvaluation = async (evaluation: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        evaluation["evaluation"] = loggedUser.id;
        if (evaluation["difficulty"] === -1){ evaluation["difficulty"] = null; }
        if (evaluation["relevance"] === -1){ evaluation["relevance"] = null; }
        if (evaluation["coherence"] === -1){ evaluation["coherence"] = null; }

        const { data, error } = await supabase.from("Evaluation").insert([evaluation]).select("id");
        if (error) {
            console.error("[insertEvaluation] Error: "+error.message);
            return null;
        } else { return data[0].id; }
    } else { return null; }
};

export const insertEvaluations = async (evaluations: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        for (var i = 0; i < evaluations.length; i++){
            evaluations[i]["user"] = loggedUser.id;
            if (evaluations[i]["difficulty"] === -1){ evaluations[i]["difficulty"] = null; }
            if (evaluations[i]["relevance"] === -1){ evaluations[i]["relevance"] = null; }
            if (evaluations[i]["coherence"] === -1){ evaluations[i]["coherence"] = null; }
        }

        const { data, error } = await supabase.from("Evaluation").insert(evaluations).select("id");
        if (error) { console.error("[insertEvaluations] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const selectEvaluations = async () => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        const { data, error } = await supabase.from("Evaluation").select().eq("user", loggedUser.id);
        if (error) { console.error("[selectEvaluations] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const selectAllEvaluations = async () => {
    const { data, error } = await supabase.from("Evaluation").select();
    if (error) { console.error("[selectAllEvaluations] Error: "+error.message); }
    return { data };
};

export const selectEvaluationById = async (id: any) => {
    const { data, error } = await supabase.from("Evaluation").select().eq("id", id);
    if (error) { console.error("[selectEvaluationById] Error: "+error.message); }
    return { data };
};

export const selectEvaluationByVariable = async (variable: string, value: any) => {
    const { data, error } = await supabase.from("Evaluation").select().eq(variable, value);
    if (error) { console.error("[selectEvaluationByVariable] Error: "+error.message); }
    return { data };
};

export const updateEvaluation = async (evaluation: any, id: any) => {
    const { error } = await supabase.from("Evaluation").update(evaluation).eq("id", id);
    if (error) {
        console.error("[updateEvaluation] Error: "+error.message);
        return false;
    } else { return true; }
};

export const deleteEvaluation = async (id: any) => {
    const { error } = await supabase.from("Evaluation").delete().eq("id", id);
    if (error) {
        console.error("[deleteEvaluation] Error: "+error.message);
        return false;
    } else { return true; }
};