import { defineMessages } from "react-intl";

export default defineMessages({
  myYards: {
    id: "app.pages.Yards.myYards",
    defaultMessage: "Meus Yards",
  },
  myYardsUrl: {
    id: "app.pages.Yards.myYardsUrl",
    defaultMessage: "/my-yards",
  },
  publicYards: {
    id: "app.pages.Yards.publicYards",
    defaultMessage: "Yards Públicos",
  },
  publicYardsUrl: {
    id: "app.pages.Yards.publicYardsUrl",
    defaultMessage: "/public-yards",
  },
  sharedYards: {
    id: "app.pages.Yards.sharedYards",
    defaultMessage: "Yards Compartilhados",
  },
  sharedYardsUrl: {
    id: "app.pages.Yards.sharedYardsUrl",
    defaultMessage: "/shared-yards",
  },
});
