import { ItemType } from "../pages/types";
import { selectContentById } from "./dao/Content";
import { selectQuestionnaryById } from "./dao/Questionnary";
import {
  deleteSharing,
  insertSharing,
  selectSharingById,
  selectSharingByVariable,
  updateSharing,
} from "./dao/Sharing";
import { selectStudyById } from "./dao/Study";
import { selectYardById } from "./dao/Yard";
import { LoginServices } from "./Login";

const getItem = (type: ItemType) => {
  let getItem = undefined;
  let getParentId = (x: any) => undefined;
  let getParentType: (x: any) => ItemType | undefined = (x: any) => undefined;

  switch (type) {
    case ItemType.Yard:
      getItem = selectYardById;
      break;

    case ItemType.Study:
      getItem = selectStudyById;
      getParentId = (study: any) => study.yard;
      getParentType = () => ItemType.Yard;
      break;

    case ItemType.Questionnary:
      getItem = selectQuestionnaryById;
      getParentId = (questionnary: any) =>
        questionnary.study || questionnary.yard;
      getParentType = (quest: any) =>
        quest.study ? ItemType.Study : ItemType.Yard;
      break;

    case ItemType.Content:
      getItem = selectContentById;
      getParentId = (content: any) => content.study;
      getParentType = () => ItemType.Study;
      break;
  }

  return { getItem, getParentId, getParentType };
};

export const requestAccess = async (itemId: string) => {
  const loggedUser = LoginServices.getLoggedUser();

  const { data } = await selectSharingByVariable("item", itemId);

  if (data) {
    const alreadyRequested =
      data.filter((x) => x.user === loggedUser.id).length > 0;

    if (alreadyRequested) {
      return;
    }
  }

  const sharing = {
    user: loggedUser.id,
    profile: "student",
    item: itemId,
    approved: false,
  };

  await insertSharing(sharing);
};

const approveSharing = async (id: string, sharing: any, itemType: ItemType) => {
  const functions = getItem(itemType);

  const { data } = await functions.getItem(id);

  if (!data) {
    throw new Error("[approveSharing] Erro ao recuperar informações.");
  }

  const approveParentSharing = async () => {
    const parentType = functions.getParentType(data[0]);
    if (parentType !== undefined && parentId) {
      await approveSharing(parentId, sharing, parentType);
    }
  };

  const item = data[0].Item;
  const parentId = functions.getParentId(data[0]);

  if (item.visibility !== "public") {
    const existingSharing = item.Sharing.filter(
      (x: any) => x.user === sharing.user
    );
    if (existingSharing.length > 0) {
      if (!existingSharing[0].approved) {
        const approvingSharing = existingSharing[0];
        approvingSharing.approved = true;
        await updateSharing(approvingSharing, approvingSharing.id);
        await approveParentSharing();
      }
    } else {
      await insertSharing({
        user: sharing.user,
        profile: "student",
        item: item.id,
        approved: true,
      });

      await approveParentSharing();
    }
  } else {
    await approveParentSharing();
  }
};

export const updateAccessRequests = async (
  id: string,
  item: any,
  accepted: any[],
  refused: any[],
  itemType: ItemType
) => {
  const deletingSharings = refused.map(
    (del) => item.Sharing.filter((x: any) => x.user === del.id)[0]
  ).filter((del) => del);

  for (let share of deletingSharings) {
    await deleteSharing(share.id);
  }

  const acceptingSharings = accepted.map(
    (acc) => item.Sharing.filter((x: any) => x.user === acc.id)[0]
  ).filter((acc) => acc);

  for (let share of acceptingSharings) {
    const { data } = await selectSharingById(share.id);
    if (data) {
      const sharing = data[0];
      sharing.approved = true;
      await approveSharing(id, sharing, itemType);
    }
  }
};
