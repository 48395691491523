import { defineMessages } from "react-intl";

export default defineMessages({
  email: {
    id: "app.pages.Register.email",
    defaultMessage: "E-mail",
  },
  password: {
    id: "app.pages.Register.password",
    defaultMessage: "Senha",
  },
  name: {
    id: "app.pages.Register.name",
    defaultMessage: "Nome",
  },
  familyName: {
    id: "app.pages.Register.familyName",
    defaultMessage: "Sobrenome",
  },
  profile: {
    id: "app.pages.Register.profile",
    defaultMessage: "Eu sou",
  },
  teacher: {
    id: "app.pages.Register.teacher",
    defaultMessage: "Docente",
  },
  student: {
    id: "app.pages.Register.student",
    defaultMessage: "Estudante",
  },
  register: {
    id: "app.pages.Register.register",
    defaultMessage: "Criar Conta",
  },
});
