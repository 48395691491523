import { LoginServices } from "../Login";
import { insertItem } from "./Item";
import supabase from "./supabaseClient";

export const insertQuestionnary = async (questionnary: any) => {
    const itemId = await insertItem(questionnary.Item);
    if (itemId !== null){
        delete questionnary["Item"];
        questionnary["item"] = itemId;
        const { data, error } = await supabase.from("Questionnary").insert([questionnary]).select("id");
        if (error) {
            console.error("[insertQuestionnary] Error: "+error.message);
            return null;
        } else { return data[0].id; }
    } else { return null; }
};

export const insertQuestionnaries = async (questionnaries: any) => {
    const data = questionnaries;
    return { data };
};

export const selectQuestionnaries = async () => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        const { data, error } = await supabase.from("Questionnary")
                                              .select("*, Yard(*), Study(*), Item!inner(*, Sharing(*))")
                                              .eq("Sharing.user", loggedUser.id);
        if (error) { console.error("[selectQuestionnaries] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const selectAllQuestionnaries = async () => {
    const { data, error } = await supabase.from("Questionnary").select("*, Item(*), Yard(*), Study(*)");
    if (error) { console.error("[selectAllQuestionnaries] Error: "+error.message); }
    return { data };
};

export const selectQuestionnaryById = async (id: any) => {
    const { data, error } = await supabase.from("Questionnary")
                                          .select("*, Item(*), Yard(*), Study(*)")
                                          .eq("id", id);
    if (error) { console.error("[selectQuestionnaryById] Error: "+error.message); }
    return { data };
};

export const selectQuestionnariesByStudy = async (study: any) => {
    const { data, error } = await supabase.from("Questionnary")
                                          .select("*, Item!inner(*, Sharing(*)), Yard(*), Study(*)")
                                          .eq("study", study);
    if (error) { console.error("[selectQuestionnariesByStudy] Error: "+error.message); }
    return { data };
};

export const selectQuestionnaryByVariable = async (variable: string, value: any) => {
    const { data, error } = await supabase.from("Questionnary")
                                          .select("*, Item(*), Yard(*), Study(*)")
                                          .eq(variable, value);
    if (error) { console.error("[selectQuestionnaryByVariable] Error: "+error.message); }
    return { data };
};

export const selectQuestionnaryByStudy = async (study: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        const { data, error } = await supabase.from("Questionnary")
                                              .select("*, Yard(*), Study(*), Item!inner(*, Sharing(*))")
                                              .eq("study", study).eq("Sharing.user", loggedUser.id);
        if (error) { console.error("[selectQuestionnaryByStudy] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const updateQuestionnary = async (questionnary: any, id: any) => {
    const { error } = await supabase.from("Questionnary").update(questionnary).eq("id", id);
    if (error) {
        console.error("[updateQuestionnary] Error: "+error.message);
        return false;
    } else { return true; }
};

export const deleteQuestionnary = async (id: any) => {
    const { error } = await supabase.from("Questionnary").delete().eq("id", id);
    if (error) {
        console.error("[deleteQuestionnary] Error: "+error.message);
        return false;
    } else { return true; }
};