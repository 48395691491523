import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.pages.Maintenance.title",
    defaultMessage: "404 - Página Não Encontrada",
  },
  text: {
    id: "app.pages.Maintenance.text",
    defaultMessage:
      "A página que você procura pode ter sido removida, mudado de nome ou está temporariamente indisponível.",
  },
  home: {
    id: "app.pages.Maintenance.home",
    defaultMessage: "Voltar para a página inicial",
  },
});
