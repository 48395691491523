import { defineMessages } from "react-intl";

export default defineMessages({
  fileUpload: {
    id: "app.containers.NewQuiz.fileUpload",
    defaultMessage: "Upload de Arquivo",
  },
  copyLink: {
    id: "app.containers.NewQuiz.copyLink",
    defaultMessage: "Copiar Link",
  },
  freeText: {
    id: "app.containers.NewQuiz.freeText",
    defaultMessage: "Texto Livre",
  },
});
