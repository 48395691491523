import { insertSharing, selectSharingId } from "./Sharing";
import { LoginServices } from "../Login";
import supabase from "./supabaseClient";

export const insertPerformance = async (performance: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        var sharingId = await selectSharingId(performance.item);
        if (sharingId === null){ sharingId = await insertSharing({"user": loggedUser.id, "item": performance.item}); }

        if (sharingId !== null){
            performance["sharing"] = sharingId;
            delete performance["item"];
            const { data, error } = await supabase.from("Performance").insert([performance]).select("id");

            if (error) {
                console.error("[insertPerformance] Error: "+error.message);
                return null;
            } else { return data[0].id; }
        } else { return null; }
    } else { return null; }
};

export const selectPerformances = async () => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        var { data, error } = await supabase.from("Sharing").select("*, Performance(*)")
                                            .eq("user", loggedUser.id);

        if (data !== null){
            for (var i = 0; i < data.length; i++){ data[i] = data[i]["Performance"]; }
        }

        if (error){ console.error("[selectPerformances] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const selectAllPerformances = async () => {
    const { data, error } = await supabase.from("Performance").select();
    if (error) { console.error("[selectAllPerformances] Error: "+error.message); }
    return { data };
};

export const selectPerformanceById = async (id: any) => {
    const { data, error } = await supabase.from("Performance").select().eq("id", id);
    if (error) { console.error("[selectPerformanceById] Error: "+error.message); }
    return { data };
};

export const selectPerformanceByVariable = async (variable: string, value: any) => {
    const { data, error } = await supabase.from("Performance").select().eq(variable, value);
    if (error) { console.error("[selectPerformanceByVariable] Error: "+error.message); }
    return { data };
};

export const selectPerformancesByItem = async (item: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        var sharingId = await selectSharingId(item);
        if (sharingId === null){ sharingId = await insertSharing({"user": loggedUser.id, "item": item}); }

        const { data, error } = await supabase.from("Performance").select().eq("sharing", sharingId);
        if (error){ console.error("[selectPerformancesByItem] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const updatePerformance = async (performance: any, id: any) => {
    const { error } = await supabase.from("Performance").update(performance).eq("id", id);
    if (error) {
        console.error("[updatePerformance] Error: "+error.message);
        return false;
    } else { return true; }
};

export const deletePerformance = async (id: any) => {
    const { error } = await supabase.from("Performance").delete().eq("id", id);
    if (error) {
        console.error("[deletePerformance] Error: "+error.message);
        return false;
    } else { return true; }
};