import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogTitle, ListItem, ListItemText, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { sleep } from "../../utils/functions";
import { addSharingInfo } from "../../services/Permissions";

export const AddSharing = ({itemId, options, open, setOpen }: any) => {
  const handleClose = () => setOpen(false);
  const [value, setValue] = useState<any[]>([]);

  const handleAddSharing = () => {

    addSharingInfo(itemId, value)
    .then(() => sleep(500)
    .then(() => {
        setOpen(false);
        setValue([]);
    }));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        display="flex"
        width="500px"
        maxHeight="400px"
        flexDirection="column"
        borderRadius={3}
        pb={2}
        mx={2}
      >
        <DialogTitle
          sx={{ width: "fit-content", paddingLeft: 0, fontWeight: "bold" }}
        >
            Adicionar usuários
        </DialogTitle>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        multiple
        id="tags-filled"
        freeSolo
        options={options||[]}
        getOptionLabel={(option:any) => option.name + " ("+option.email+")"}
        renderOption={(props, option:any) => (
            <ListItem {...props}>
                <ListItemText primary={option.name} secondary={option.email} />
            </ListItem>
          )}
        renderTags={(value: any[], getTagProps) =>
          value.map((option: any, index: number) => (
            <Tooltip key={index} title={option.email}>
            <Chip
              variant="outlined"
              label={option.name}
              {...getTagProps({ index })}
            />
            </Tooltip>
          ))
        }
        filterOptions={(options, state) => {
            if (state.inputValue.length > 0) {
              return options.filter((item:any) =>
                item.name.toLowerCase().includes(state.inputValue.toLowerCase())
              );
            }
            return [];
          }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Usuários"
          />
        )}
      />
       <DialogActions>
          <Button onClick={handleAddSharing}>
            Salvar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
