import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { NewCardDialog } from "./NewCardDialog";
import { LoginServices } from "../../services/Login";

interface NewYardProps {
  isGenericYardPage: boolean | undefined;
  id: number | undefined | string;
  hidden: boolean;
}

export const NewYard = ({ isGenericYardPage, id, hidden }: NewYardProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buttonTitle = isGenericYardPage ? "Novo Yard" : "Novo Estudo";
  var createNewYard = isGenericYardPage && LoginServices.getLoggedUser();

  return (
    <>
      {(createNewYard || !hidden) && (
        <div>
          <Button variant="contained" onClick={handleClickOpen}>
            <Typography>{buttonTitle}</Typography>
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <NewCardDialog
              isGenericYardPage={isGenericYardPage}
              handleClose={handleClose}
              id={id}
            />
          </Dialog>
        </div>
      )}
    </>
  );
};
