import { ChangeEvent, forwardRef, useImperativeHandle, useState } from "react";
import { RequiredTextFieldProps, ValidationInputHandle } from "../types";
import { LoginValidationServices } from "../../services/LoginValidation";
import { TextField } from "@mui/material";

export const RequiredTextField = forwardRef<ValidationInputHandle, RequiredTextFieldProps>(({ type,
                                                                                              value,
                                                                                              setValue,
                                                                                              onlyText,
                                                                                              ...props},
                                                                                              ref) => {

    const [message, setMessage] = useState("");
    const [isValid, setIsValid] = useState(true);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const onlyTextRegex = /[^a-z\s]/gi;

        const newValue = e.currentTarget.value;
        setValue(onlyText ? newValue.replace(onlyTextRegex, '') : newValue);
    };

    const validateComponent = () => {
        try {
            LoginValidationServices.validateRequired(value);
            
            setIsValid(true);
            setMessage("");
            return true;
        }
        catch (err) {
            setIsValid(false);
            setMessage((err as Error).message);
            return false;
        }
    };

    useImperativeHandle(ref, () => ({validateComponent}));

    return (
        <TextField  required
                    error={!isValid}
                    type={type || 'text'}
                    value={value}
                    onChange={handleChange}
                    onBlur={validateComponent}
                    helperText={message}
                    {...props}/>
    );

});
