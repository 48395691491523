import { ItemType, PermissionProps } from "../pages/types";
import { LoginServices } from "./Login";
import { selectContentById, selectContentsByStudy } from "./dao/Content";
import { selectQuestionnaryById, selectQuestionnaryByStudy, selectQuestionnaryByVariable } from "./dao/Questionnary";
import { deleteSharing, insertSharings, selectSharingByVariable, updateSharing } from "./dao/Sharing";
import { selectStudiesByYard, selectStudyById } from "./dao/Study";
import { selectAllUsers, selectUserById, selectUsersById } from "./dao/User";
import { selectYardById } from "./dao/Yard";

const getItem = (
  type: ItemType
) => {
  let getItem = undefined;
  let getParentId = (x:any) => undefined;

  switch (type) {
    case ItemType.Yard:
      getItem = selectYardById;
      break;

    case ItemType.Study:
      getItem = selectStudyById;
      getParentId = (study:any) => study.yard;
      break;

    case ItemType.Questionnary:
      getItem = selectQuestionnaryById;
      getParentId = (questionnary:any) => questionnary.study || questionnary.yard;
      break;

    case ItemType.Content:
      getItem = selectContentById;
      getParentId = (content:any) => content.study;
      break;
  }

  return { getItem, getParentId };
}

export const getSharingInfo = async (
  id: string,
  type: ItemType
) => {
  
  const usersWithAccess: any[] = [];
  const accessRequests: any[] = [];
  const usersAbleToAccess: any[] = [];
  let checkAscendantItem = true;
  let currentType = type;
  
  const getFunctions = getItem(type);
  const {data} = await getFunctions.getItem(id);

  if (!data) {
    throw new Error("Erro ao recuperar informações de compartilhamento.");
  }

  const item = data[0].Item;
  let currentParent = getFunctions.getParentId(data[0]);

  const mapSharingInfo = (user: any, list: any[]) => {
    var isCreator = user.id === item.creator;

    list.push({
      id: user.id,
      name: user.name + " " + user.familyName,
      email: user.email,
      isCreator: isCreator
    });
  };

  const mapCreatorSharingInfo = async (item: any) => {
    let creatorAdded = usersWithAccess.filter((u) => u.id === item.creator);
    if (creatorAdded.length > 0) {
      creatorAdded[0].isCreator = true;
    } else {
      const { data } = await selectUserById(item.creator);
      if (data) {
        usersWithAccess.push({
          id: data[0].id,
          name: data[0].name + " " + data[0].familyName,
          email: data[0].email,
          isCreator: true,
        });
      }
    }
  };

  const checkUsersAbleToAccess = async (ascendantItem: any) => {
    const usersToSelect = ascendantItem.Sharing
      .filter((x: any) => x.approved)
      .filter((x: any) => usersWithAccess.filter((y) => y.id === x.user).length <= 0)
      .filter((x: any) => usersAbleToAccess.filter((y) => y.id === x.user).length <= 0)
      .map((x: any) => x.user);

    const { data: usersSelected } = await selectUsersById(usersToSelect);
    if (usersSelected) {
      usersSelected.map(user => usersAbleToAccess.push({
        id: user.id,
        name: user.name + " " + user.familyName,
        email: user.email
      }));
    }

    checkAscendantItem = ascendantItem.visibility === "public";
  };

  const usersId = item.Sharing
                    .filter((x:any) => x.approved)
                    .map((share: any) => share.user);

  const { data: users } = await selectUsersById(usersId);
  if (users) {
    users.map(u => mapSharingInfo(u, usersWithAccess));
    await mapCreatorSharingInfo(item);
  }

  const usersRequestsId = item.Sharing
                    .filter((x:any) => !x.approved)
                    .map((share: any) => share.user);

  const { data: requests } = await selectUsersById(usersRequestsId);
  if (requests) {
    requests.map(u => mapSharingInfo(u, accessRequests));
    await mapCreatorSharingInfo(item);
  }

  if (currentType === ItemType.Questionnary) {
    const { data: study } = await selectStudyById(currentParent);

    if (study && study.length) {
      await mapCreatorSharingInfo(study[0].Item);
      await checkUsersAbleToAccess(study[0].Item);
      currentParent = study[0].yard;
    }

    currentType = ItemType.Study;
  }

  if (currentType === ItemType.Content) {
    const { data: study } = await selectStudyById(currentParent);

    if (study && study.length) {
      await mapCreatorSharingInfo(study[0].Item);
      await checkUsersAbleToAccess(study[0].Item);
      currentType = ItemType.Study;
      currentParent = study[0].yard;
    }
  }

  if (checkAscendantItem && currentType === ItemType.Study) {
    const { data: yard } = await selectYardById(currentParent);

    if (yard && yard.length) {
      await mapCreatorSharingInfo(yard[0].Item);
      await checkUsersAbleToAccess(yard[0].Item);
      currentType = ItemType.Yard;
    }
  }

  if (checkAscendantItem && currentType === ItemType.Yard) {
    const { data: activeUsers } = await selectAllUsers();
    if (activeUsers) {
      const filteredUsers = activeUsers
        .filter((x: any) => usersWithAccess.filter((y) => y.id === x.id).length <= 0);

      filteredUsers.map(user => usersAbleToAccess.push({
        id: user.id,
        name: user.name + " " + user.familyName,
        email: user.email
      }));
    }
  }
  
  return {
    item: item,
    usersWithAccess: usersWithAccess,
    usersAbleToAccess: usersAbleToAccess,
    accessRequests: accessRequests
  };
};

export const checkPermissions = async (
  item: any,
  parentId: string | undefined,
  type: ItemType
) => {
  const creatorsList = [item.creator];
  let currentType = type;
  let currentParent = parentId;

  const user = LoginServices.getLoggedUser();
  var isPublic = item.visibility === "public";
  var sharing = item.Sharing;

  const checkInheritedPermission = (ascendantItem: any) => {
    if (isPublic) {
      sharing = sharing.concat(ascendantItem.Sharing.filter((sh:any) => sh.approved));

      isPublic = ascendantItem.visibility === "public";
    }
  };

  if (currentType === ItemType.Questionnary) {
    const { data: study } = await selectStudyById(currentParent);

    if (study && study.length) {
      creatorsList.push(study[0].Item.creator);
      currentParent = study[0].yard;
      checkInheritedPermission(study[0].Item);
    }

    currentType = ItemType.Study;
  }

  if (currentType === ItemType.Content) {
    const { data: study } = await selectStudyById(currentParent);

    if (study && study.length) {
      creatorsList.push(study[0].Item.creator);
      currentType = ItemType.Study;
      currentParent = study[0].yard;
      checkInheritedPermission(study[0].Item);
    }
  }

  if (currentType === ItemType.Study) {
    const { data: yard } = await selectYardById(currentParent);

    if (yard && yard.length) {
      creatorsList.push(yard[0].Item.creator);
      checkInheritedPermission(yard[0].Item);
    }
  }

  if (!user) {
    return {
      view: isPublic,
    } as PermissionProps;
  }

  const checkPermission = (profiles: string[]) =>
    sharing.filter(
      (share: any) =>
        share.user === user.id && share.approved && profiles.indexOf(share.profile) >= 0
    ).length > 0;

  const creatorsPermission = creatorsList.includes(user.id);

  const hasAccess =
    sharing.filter((share: any) => share.user === user.id && share.approved).length > 0 ||
    creatorsPermission;

  const hasRequestsToAnswer = item.Sharing.filter((share: any) => !share.approved).length > 0;
  const alreadyRequestedAccess = 
      item.Sharing.filter((share: any) => share.user === user.id &&!share.approved).length > 0;

  const priviledgedPermission =
    checkPermission(["teacher", "monitor"]) || creatorsPermission;

  return {
    edit: priviledgedPermission,
    view: hasAccess || isPublic,
    share: priviledgedPermission && item.visibility !== "public",
    hasRequestsToAnswer: hasRequestsToAnswer && priviledgedPermission,
    alreadyRequestedAccess
  };
};

export const deleteSharingInfo = async (id:string, item: any, toDelete: any[], type:ItemType) => {
  const deletingSharings = toDelete.map(
    (del) => item.Sharing.filter((x: any) => x.user === del.id)[0]
  ).filter((del) => del);

  for (let share of deletingSharings) {
    await deleteSharing(share.id);
  }

  if (type === ItemType.Yard) {
    const {data:studies} = await selectStudiesByYard(id);
    if (studies) {
      for (let study of studies) {
        await deleteSharingInfo(study.id, study.Item, toDelete, ItemType.Study);
      }
    }

    const {data:questionnaries} = await selectQuestionnaryByVariable("yard", id);
    if (questionnaries) {
      for (let questionnary of questionnaries) {
        await deleteSharingInfo(questionnary.id, questionnary.Item, toDelete, ItemType.Questionnary);
      }
    }
  }

  if (type === ItemType.Study) {
    const {data:questionnaries} = await selectQuestionnaryByStudy(id);
    if (questionnaries) {
      for (let questionnary of questionnaries) {
        await deleteSharingInfo(questionnary.id, questionnary.Item, toDelete, ItemType.Questionnary);
      }
    }

    const {data:contents} = await selectContentsByStudy(id);
    if (contents) {
      for (let content of contents) {
        await deleteSharingInfo(content.id, content.Item, toDelete, ItemType.Content);
      }
    }
  }  
}

export const addSharingInfo = async (itemId:string, users:any[]) => {

  const { data:sharings } = await selectSharingByVariable("item", itemId);

  sharings?.filter((s:any) => users.filter((u:any) => s.user === u.id).length)
    .map(async (val:any) => { 
      val.approved = true;
      await updateSharing(val, val.id);
    });

  const newSharings = users
    .filter(u => !sharings?.filter((s:any) => s.user === u.id).length)
    .map(val => ({
      user: val.id,
      profile: "student",
      item:itemId,
      approved:true
    }));

  if (newSharings.length)
    await insertSharings(newSharings);
}