import { useIntl } from "react-intl";
import LayersIcon from "@mui/icons-material/Layers";
import RocketIcon from "@mui/icons-material/Rocket";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "../Link/Link";
import messages from "../../pages/Yards/messages";

const mainListItems = [
  {
    title: { ...messages.myYards },
    icon: <RocketIcon />,
    href: "/my-yards",
    disabled: false,
  },
  {
    title: { ...messages.publicYards },
    icon: <LayersIcon />,
    href: "/public-yards",
    disabled: false,
  },
  {
    title: { ...messages.sharedYards },
    icon: <SearchIcon />,
    href: "/shared-yards",
    disabled: false,
  },
];

export const SideBar = () => {
  const intl = useIntl();

  return (
    <Box
      position="relative"
      display="flex"
      height="100%"
      width="320px"
      px={2}
      sx={{
        backgroundColor: "whitesmoke",
      }}
    >
      <Box>
        <List component="nav" sx={{ p: 0 }}>
          {mainListItems.map(({ title, icon, href, disabled }, index) => (
            <Link key={index} href={disabled ? "#" : href}>
              <ListItemButton
                sx={{ mx: 0, borderRadius: 4 }}
                disabled={disabled}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={intl.formatMessage(title)} />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Box>
    </Box>
  );
};
