import { Box, Button, Divider, Paper } from "@mui/material";
import { RequiredTextField } from "../../components/RequiredTextField/RequiredTextField";
import { EmailInput } from "../../components/EmailInput/EmailInput";
import { PasswordInput } from "../../components/PasswordInput/PasswordInput";
import { FormattedMessage, useIntl } from "react-intl";
import { GoogleLoginInput } from "../../components/GoogleLoginInput/GoogleLoginInput";
import { ValidationInputHandle } from "../../components/types";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginServices } from "../../services/Login";
import { Pages } from "../../utils/constants";
import { RegisterServices } from "../../services/Register";
import messages from "./messages";
import { CustomAlert } from "../../components/CustomAlert/CustomAlert";
import "./styles.css";

export const RegisterForm = () => {
  const intl = useIntl();

  const nameRef = useRef<ValidationInputHandle>(null);
  const familyNameRef = useRef<ValidationInputHandle>(null);
  const emailRef = useRef<ValidationInputHandle>(null);
  const passwordRef = useRef<ValidationInputHandle>(null);

  const [user, setUser] = useState({
    name: "",
    familyName: "",
    email: "",
    password: ""
  });
  const [error, setError] = useState("");

  const navigate = useNavigate();

  let passed: any;
  const { state } = useLocation();
  if (state) {
    passed = state.user;
  }

  useEffect(() => {
    if (passed) {
      setUser({
        name: passed.name,
        familyName: passed.familyName,
        email: passed.email,
        password: passed.password
      });
    }
  }, [passed]);

  const handleLoginSuccess = (user: any) => {
    LoginServices.handleLoginSuccess(user).then(() => navigate(Pages.Home));
  };

  const handleLoginClick = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let validUser = [
      nameRef.current && nameRef.current.validateComponent(),
      familyNameRef.current && familyNameRef.current.validateComponent(),
      emailRef.current && emailRef.current.validateComponent(),
      passwordRef.current && passwordRef.current.validateComponent(),
    ];

    if (!validUser.includes(false)) {
      RegisterServices.createAccount(user, !passed)
        .then((_) => navigate(Pages.Home))
        .catch((err) => setError(err.message));
    }
  };

  return (
    <Box
      height={"100%"}
      className="border-radius-8"
      display="flex"
      alignItems={"flex-start"}
      flexDirection={{ xs: "row", sm: "row", md: "column" }}
      justifyContent={"center"}
    >
      <Paper elevation={3}>
        <Box
          id="register-form"
          component="form"
          display="flex"
          justifyContent={"space-around"}
          flexDirection={"column"}
          noValidate
          autoComplete="off"
          px={"5%"}
          py={"5%"}
          alignItems={"center"}
          sx={!passed ? {minHeight: "530px"} : {minHeight:"330px"}}
          onSubmit={handleLoginClick}
        >
          <RequiredTextField
            label={intl.formatMessage(messages.name)}
            ref={nameRef}
            value={user.name}
            setValue={(value) =>
              setUser({
                name: value,
                familyName: user.familyName,
                email: user.email,
                password: user.password
              })
            }
            onlyText
            variant="outlined"
            fullWidth
            className="border-radius-8"
          />

          <RequiredTextField
            label={intl.formatMessage(messages.familyName)}
            ref={familyNameRef}
            value={user.familyName}
            setValue={(value) =>
              setUser({
                name: user.name,
                familyName: value,
                email: user.email,
                password: user.password
              })
            }
            onlyText
            variant="outlined"
            fullWidth
            className="border-radius-8"
          />

          <EmailInput
            id="email"
            ref={emailRef}
            isRequired
            emailLabel={intl.formatMessage(messages.email)}
            email={user.email}
            setEmail={(value) =>
              setUser({
                name: user.name,
                familyName: user.familyName,
                email: value,
                password: user.password
              })
            }
            autoComplete="off"
            variant="outlined"
            fullWidth
            className="border-radius-8"
          />

          {!passed && (
            <PasswordInput
              id="password"
              ref={passwordRef}
              label={intl.formatMessage(messages.password)}
              password={user.password}
              setPassword={(value) =>
                setUser({
                  name: user.name,
                  familyName: user.familyName,
                  email: user.email,
                  password: value
                })
              }
              validateStrength
              variant="outlined"
              fullWidth
              className="border-radius-8"
            />
          )}

          <Button
            id="register-button"
            type="submit"
            variant="contained"
            fullWidth
          >
            <FormattedMessage {...messages.register} />
          </Button>

          {!passed && (
            <>
              <Divider variant="middle" sx={{ width: "100%" }} />

              <GoogleLoginInput
                onSuccess={handleLoginSuccess}
                onError={setError}
              />
            </>
          )}
        </Box>
      </Paper>

      <CustomAlert message={error} setMessage={setError} severity="error" />
    </Box>
  );
};
