import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { CardProps } from "./types";
import { CardSettings } from "../CardSettings";
import { useEffect, useState } from "react";
import { ItemType } from "../../pages/types";
import { checkPermissions } from "../../services/Permissions";
import { Lock } from "@mui/icons-material";
import { RequestAccessButton } from "../RequestAccessButton";

const Link = styled.a`
  color: initial;
  text-decoration: none;
  position: relative;
  display: block;
`;

export const YardCard = ({
  title,
  description,
  tags,
  id,
  isGenericYardPage,
  yard,
  item,
  yardPermissions,
}: CardProps) => {
  const href = isGenericYardPage ? `/yard/${id}` : `/study/${id}`;
  const [permissions, setPermissions] = useState(yardPermissions);
  const isYard = !yard;

  useEffect(() => {
    if (isYard) {
      checkPermissions(item, undefined, ItemType.Yard).then(setPermissions);
    } else {
      checkPermissions(item, yard, ItemType.Study).then(setPermissions);
    }
  }, [isYard, item, yard]);

  const component = (
    <Box p={2} position={"relative"}>
      <Box
        maxHeight="45px"
        display="flex"
        position="relative"
        overflow="hidden"
        fontWeight="bold"
        justifyContent="space-between"
      >
        <Typography
          variant="subtitle2"
          width="100%"
          fontWeight="bold"
          align="center"
        >
          {title}
        </Typography>
        {!permissions?.view && (
          <Lock scale={"small"} sx={{ color: "#919191" }} />
        )}
        <CardSettings
          isGenericYard={isGenericYardPage}
          id={id}
          isYard={isYard}
          permissions={permissions}
        />
      </Box>
      <Box
        py={2}
        maxHeight={{ xs: "80px", md: "100px" }}
        display="flex"
        position="relative"
        overflow="hidden"
      >
        <Typography
          variant="body2"
          width="100%"
          color="text.secondary"
          align="center"
        >
          {description}
        </Typography>
      </Box>
      <Box py={2} maxHeight="70px" display="inline" overflow="hidden">
        {tags.map((tag, index) => (
          <Box
            key={index}
            display="inline-block"
            border={1}
            borderColor="white"
            borderRadius={3}
            mr={2}
            my={0.5}
            p={0.5}
            sx={{ backgroundColor: "lightgreen" }}
          >
            <Typography variant="body2">{tag}</Typography>
          </Box>
        ))}
      </Box>
      {!permissions?.view && (
        <RequestAccessButton itemId={item.id} permissions={permissions} />
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        width: { xs: 260, md: 280 },
        height: { xs: 220, md: 245 },
        backgroundColor: "ghostwhite",
        borderRadius: 2,
      }}
    >
      {permissions?.view ? <Link href={href}>{component}</Link> : component}
    </Box>
  );
};
