import { AccessTime, PersonAddAlt1 } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { RequestAccessButtonProps } from "../types";
import { requestAccess } from "../../services/AccessRequests";
import { sleep } from "../../utils/functions";
import { LoginServices } from "../../services/Login";

export const RequestAccessButton = ({
  itemId,
  permissions,
}: RequestAccessButtonProps) => {
  const loggedUser = LoginServices.getLoggedUser();

  const handleRequestAccessClick = () => {
    requestAccess(itemId).then(() => {
      sleep(500).then(() => window.location.reload());
    });
  };

  return loggedUser ? (
    <Button
      variant={"contained"}
      disabled={permissions?.alreadyRequestedAccess}
      sx={{
        bottom: "10%",
        width: "80%",
        borderRadius: 3,
        position: "absolute",
        left: "10%",
        "&.Mui-disabled": {
          backgroundColor: "#CDCDCD",
          color: "#525252",
          boxShadow:
            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
        },
      }}
      onClick={handleRequestAccessClick}
    >
      {permissions?.alreadyRequestedAccess ? <AccessTime /> : <PersonAddAlt1 />}
      <Typography sx={{ px: 2 }} variant="button">
        {permissions?.alreadyRequestedAccess
          ? "Acesso solicitado"
          : "Solicitar acesso"}
      </Typography>
    </Button>
  ) : <></>;
};
