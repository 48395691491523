import TextField from "@mui/material/TextField";
import  { EmailInputProps, ValidationInputHandle } from "../types";
import { useState, forwardRef, useImperativeHandle } from "react";
import { LoginValidationServices } from "../../services/LoginValidation";

export const EmailInput = forwardRef<ValidationInputHandle, EmailInputProps>(({ email, 
                                                                                setEmail, 
                                                                                isRequired, 
                                                                                emailLabel, 
                                                                                ...otherProps}, 
                                                                                ref) => {    
        
        const [message, setMessage] = useState("");
        const [isValid, setIsValid] = useState(true);
        
        const validateComponent = () => {

            try {
                LoginValidationServices.validateEmail(email, isRequired as boolean);
        
                setIsValid(true);
                setMessage("");
                return true;
            }
            catch(err) {
                setIsValid(false);
                setMessage((err as Error).message);
                return false;
            };
        }

        useImperativeHandle(ref, () => ({validateComponent}));

        return (
            <TextField  error={!isValid}
                        required={isRequired}
                        label={emailLabel || "Email"}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value) }
                        onBlur={validateComponent}
                        helperText={message}
                        {...otherProps} />
        );
    }
);