import { CheckCircle, HighlightOff } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { updateAccessRequests } from "../../services/AccessRequests";
import { sleep } from "../../utils/functions";

const RequestItem = ({ user, handleAccept, handleRefuse }: any) => {
  const [selected, setSelected] = useState({ accept: false, refuse: false });

  const handleAcceptClick = () => {
    setSelected({ accept: !selected.accept, refuse: false });
    handleAccept(true);
    handleRefuse(false);
  };

  const handleRefuseClick = () => {
    setSelected({ accept: false, refuse: !selected.refuse });
    handleAccept(false);
    handleRefuse(true);
  };

  const sx = { cursor: "pointer" };

  return (
    <ListItem disableGutters>
      <ListItemText primary={user.name} secondary={user.email} />
      <Tooltip title="Aceitar">
        <ListItemIcon
          sx={selected.accept ? { cursor: "pointer", color: "green" } : sx}
          onClick={handleAcceptClick}
        >
          <CheckCircle />
        </ListItemIcon>
      </Tooltip>
      <Tooltip title="Recusar">
        <ListItemIcon
          sx={selected.refuse ? { cursor: "pointer", color: "red" } : sx}
          onClick={handleRefuseClick}
        >
          <HighlightOff />
        </ListItemIcon>
      </Tooltip>
    </ListItem>
  );
};

export const RequestsTab = ({ id, item, itemType, accessRequests, updateData }: any) => {
  const [loading, setLoading] = useState(false);
  const [toRefuse, setToRefuse] = useState<any>({});
  const [toAccept, setToAccept] = useState<any>({});

  const updateToRefuse = (id: string, refuse: boolean) => {
    toRefuse[id] = refuse;
    setToRefuse(toRefuse);
  };

  const updateToAccept = (id: string, accept: boolean) => {
    toAccept[id] = accept;
    setToAccept(toAccept);
  };

  const handleSaveRequests = () => {
    setLoading(true);
    const accepted = accessRequests?.filter((x:any) => toAccept[x.id]) || [];
    const refused = accessRequests?.filter((x:any) => toRefuse[x.id]) || [];
    updateAccessRequests(id, item, accepted, refused, itemType).then(() =>
      sleep(500).then(() => updateData())
    );
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <List sx={{ pt: 0 }}>
          {accessRequests &&
            accessRequests.length > 0 &&
            accessRequests.map((user:any, index:number) => (
              <RequestItem
                user={user}
                itemId={item?.id}
                handleAccept={(accept: boolean) =>
                  updateToAccept(user?.id, accept)
                }
                handleRefuse={(refuse: boolean) =>
                  updateToRefuse(user?.id, refuse)
                }
                key={index}
              />
            ))}
        </List>
      )}
      <DialogActions>
        <Button onClick={handleSaveRequests} disabled={loading}>
          Salvar alterações
        </Button>
      </DialogActions>
    </>
  );
};
