import { ContentUploadOptionHandle } from "./types";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { RequiredTextField } from "../../components/RequiredTextField/RequiredTextField";
import { ValidationInputHandle } from "../../components/types";

export const CopyLink = forwardRef<ContentUploadOptionHandle, unknown>((_, ref) => {

  const linkRef = useRef<ValidationInputHandle>(null);
  const [link, setLink] = useState("");

  useImperativeHandle(ref, () => ({
    validateComponent: () =>  {
      if (!linkRef.current || !linkRef.current?.validateComponent()) {
        return false;
      }

      const urlRegex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;
      if (!urlRegex.test(link)) {
        throw new Error("Informe um link válido.");
      }

      return true;
    }, 
    buildContent: () => ({ url: link })
  }));

  return (
    <RequiredTextField
      ref={linkRef}
      value={link}
      setValue={setLink}
      sx={{
        justifyContent: "center",
        width: "100%",
      }}
      label="Insira aqui seu link"
    />
  );
});