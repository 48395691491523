export class LocalStorage {
    static readonly Login: string = "StudyardLogin";
  }
  
  export class Pages {
    static readonly Login: string = "/login";
    static readonly Register: string = "/register";
    static readonly Home: string = "/public-yards";
    static readonly MyYards: string = "/my-yards";
  }
  
  export class Password {
    static readonly MaxLength: number = 20;
    static readonly MinLength: number = 8;
  }
  