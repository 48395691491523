import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import messages from "./messages";
import { FormEvent } from "react";
import { EmailInput } from "../../components/EmailInput/EmailInput";
import { PasswordInput } from "../../components/PasswordInput/PasswordInput";
import { FormattedMessage, useIntl } from "react-intl";
import { useRef, useState } from "react";
import { Divider, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ValidationInputHandle } from "../../components/types";
import { GoogleLoginInput } from "../../components/GoogleLoginInput/GoogleLoginInput";
import { Pages } from "../../utils/constants";
import { LoginServices } from "../../services/Login";
import { CustomAlert } from "../../components/CustomAlert/CustomAlert";
import "./styles.css";

export const LoginForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const emailRef = useRef<ValidationInputHandle>(null);
  const passwordRef = useRef<ValidationInputHandle>(null);

  const [values, setValues] = useState({ email: "", password: "" });
  const [error, setError] = useState("");

  const handleLoginSuccess = (user: any) => {
    LoginServices.handleLoginSuccess(user).then(() => navigate(Pages.Home));
  };

  const handleLoginClick = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let emailValid = emailRef.current && emailRef.current.validateComponent();
    let passValid =
      passwordRef.current && passwordRef.current.validateComponent();

    if (emailValid && passValid) {
      LoginServices.checkLogin(values.email, values.password)
        .then((res) => handleLoginSuccess(res))
        .catch((err) => setError(err.message));
    }
  };

  return (
    <Box
      height={"100%"}
      className="border-radius-8"
      display="flex"
      alignItems={"flex-start"}
      flexDirection={{ xs: "row", sm: "row", md: "column" }}
      justifyContent={"center"}
    >
      <Paper elevation={3}>
        <Box
          id="login-form"
          component="form"
          display="flex"
          justifyContent={"space-around"}
          flexDirection={"column"}
          noValidate
          autoComplete="off"
          px={"5%"}
          py={"5%"}
          alignItems={"center"}
          onSubmit={handleLoginClick}
        >
          <EmailInput
            id="email"
            ref={emailRef}
            isRequired
            emailLabel={intl.formatMessage(messages.username)}
            email={values.email}
            setEmail={(value) =>
              setValues({ email: value, password: values.password })
            }
            autoComplete="off"
            variant="outlined"
            fullWidth
            className="border-radius-8"
          />

          <PasswordInput
            id="password"
            ref={passwordRef}
            label={intl.formatMessage(messages.password)}
            password={values.password}
            setPassword={(value) =>
              setValues({ email: values.email, password: value })
            }
            variant="outlined"
            fullWidth
            className="border-radius-8"
          />

          <Button id="login-button" type="submit" variant="contained" fullWidth>
            <FormattedMessage {...messages.login} />
          </Button>

          <Divider variant="middle" sx={{ width: "100%" }} />

          <GoogleLoginInput onSuccess={handleLoginSuccess} onError={setError} />
        </Box>
      </Paper>

      <CustomAlert message={error} setMessage={setError} severity="error" />
    </Box>
  );
};
