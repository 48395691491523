import styled from "@emotion/styled";
import {Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { forwardRef, useCallback, useImperativeHandle } from "react";
import { useDropzone } from "react-dropzone";
import { ContentUploadOptionHandle } from "./types";

const Wrapper = styled.div`
  cursor: pointer;
  display: block;
  justify-content: center;
  align-items: center;
`;

const Drop = styled.input``;

export const FileUpload = forwardRef<ContentUploadOptionHandle, unknown>((_, ref) => {
  const onDrop = useCallback((acceptedFiles: any) => {}, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop, maxFiles:1 });

  const archiveMessage = (isDragActive: boolean) =>
    isDragActive
      ? "Solte o arquivo aqui"
      : "Arraste um arquivo até este espaço, ou clique para selecionar um arquivo";

  useImperativeHandle(ref, () => ({
    validateComponent: () => {
      if (!acceptedFiles[0]) {
        throw new Error("Selecione um arquivo para importar.");
      }
  
      return true;
    }, 
    buildContent: () => ({ file: acceptedFiles[0] })
  }));

  return (
    <>
      <Box
        position="relative"
        display="flex"
        maxWidth="600px"
        height="140px"
        width="100%"
        justifyContent="center"
        textAlign="center"
        sx={{
          backgroundColor: "whitesmoke",
        }}
        border="1px dashed gray"
        {...getRootProps()}
      >
        <Wrapper>
          {!acceptedFiles[0] && (
            <>
              <Drop {...getInputProps()} />
              <Typography variant="subtitle2" p={2} display="flex">
                {archiveMessage(isDragActive)}
              </Typography>
            </>
          )}
          {acceptedFiles[0] && (
            <Typography variant="subtitle2" p={2} display="flex">
              {acceptedFiles[0].name}
            </Typography>
          )}
        </Wrapper>
      </Box>
    </>
  );
});
