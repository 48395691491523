import { useState } from "react";
import { Box, Button, Popover, Typography } from "@mui/material";
import { BreadcrumbsProps } from "../types";

export const AppBarBreadcrumb = ({ title, options }: BreadcrumbsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button onClick={handleClick} sx={{ borderRadius: 4, mr: 3 }}>
        {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {options?.map(({ text, icon, optionClick }, index) => (
          <Box key={index} display="flex" alignItems="center" px={2}>
            <Button onClick={optionClick} sx={{ color: "black" }}>
              {icon}
              <Typography sx={{ p: 2 }}>{text}</Typography>
            </Button>
          </Box>
        ))}
      </Popover>
    </div>
  );
};
