import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  LinearProgress,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AppBar } from "../../components/AppBar";
import { SideBar } from "../../components/SideBar";
import { ItemType, PermissionProps, StudiesProps } from "../types";
import { NewContent } from "../../containers/NewContent";
import { Breadcrumb } from "../../components/Breadcrumb";
import { selectYardById } from "../../services/dao/Yard";
import { LoadContentCards } from "../../components/Card/ContentCard";
import { LoadQuestionnaryCards } from "../../components/Card/QuestionnaryCard";
import { ExpandMore } from "@mui/icons-material";
import { NewQuestionnary } from "../../containers/NewQuestionnary";
import "./styles.css";
import { checkPermissions } from "../../services/Permissions";
import { BlockedAccess } from "../../components/BlockedAccessWarning";
import { InviteButton } from "../../components/InviteButton";

export const Studies = ({ id, yard: yardId, Item: item }: StudiesProps) => {
  const [yard, setYard] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [editPermission, setEditPermission] = useState<
    PermissionProps | undefined
  >(undefined);

  const yardUrl = `/yard/${yardId}`;

  useEffect(() => {
    selectYardById(yardId).then(({ data }) => {
      if (data) {
        setYard(data[0]);
        checkPermissions(item, yardId?.toString(), ItemType.Study)
          .then(setEditPermission)
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    });
  }, [yardId, item]);

  const breadcrumbTitle = (
    <>
      <Typography variant="h5" color="black" pr={1}>
        {item.title}
      </Typography>
      {editPermission?.edit && <ArrowDropDownIcon sx={{ color: "black" }} />}
    </>
  );

  return (
    <Box display="flex" flexDirection="column">
      <AppBar />
      <Box display="flex" flexDirection="row">
        <SideBar />
        <Box flexDirection="column" m={3} width="100%">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" flexDirection="row" pb={3}>
              <Button sx={{ borderRadius: 4, p: 1 }} href={yardUrl}>
                <Typography variant="h5" color="#605a56">
                  {yard && yard.Item.title}
                </Typography>
              </Button>
              <KeyboardArrowRightIcon sx={{ mx: 1 }} />
              <Breadcrumb
                title={breadcrumbTitle}
                id={id}
                isYard={false}
                permissions={editPermission}
              />
            </Box>
            <Box display="flex" flexDirection="row">
              <Box pl={2}>
                <InviteButton
                  id={id}
                  itemType={ItemType.Study}
                  permissions={editPermission}
                />
              </Box>
            </Box>
          </Box>
          {loading && <LinearProgress />}
          {!loading && editPermission?.view && (
            <>
              <Accordion id="content-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography pl={1} variant="h6" color="black">
                    Conteúdos
                  </Typography>
                  <Box display="flex" flexDirection="row">
                    <NewContent
                      id={id}
                      Item={item}
                      permissions={editPermission}
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <LoadContentCards study={id} />
                </AccordionDetails>
              </Accordion>
              <Accordion id="quizzes-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography pl={1} variant="h6" color="black">
                    Questionários
                  </Typography>
                  <Box display="flex" flexDirection="row">
                    <NewQuestionnary hidden={!editPermission?.edit} />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <LoadQuestionnaryCards study={id} />
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {!loading && !editPermission?.view && (
            <BlockedAccess itemId={item.id} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
