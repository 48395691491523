import { jwtDecode } from "jwt-decode";
import { encrypt, decrypt } from "../utils/Encryption";
import { LocalStorage } from "../utils/constants";
import supabase from "./dao/supabaseClient";
import { CredentialResponse } from "@react-oauth/google";

export const LoginServices = {

    getLoggedUser: () => {
        var login = localStorage.getItem(LocalStorage.Login); 
        if(login) { 
            var loggedIn = JSON.parse(decrypt(login));
           
            if (new Date(loggedIn.expiresIn) >= new Date()) {
                return loggedIn;
            }

            localStorage.removeItem(LocalStorage.Login);
        }
        
        return null;
    },

    checkLogin: async (email:string, password:string) => {
        const {data, error} = await supabase.from("User")
                                                .select()
                                                .eq("email", email)
                                                .eq("internal", true)
                                                .eq("confirmed", true);
                    
        const dataFiltered = data?.filter(user => decrypt(user.password) === password);
        
        if (error) {
            throw new Error("Houve um erro ao fazer login. Tente novamente mais tarde!");
        }
        
        if (!dataFiltered || !dataFiltered.length) {
            throw new Error("Usuário e/ou senha inválidos.");
        }
        
        return dataFiltered[0];
    },

    handleLoginSuccess: async (user:any) => {
        const daysToExpire = 5;
        user.expiresIn = new Date((new Date()).getTime() + (daysToExpire*24*60*60000));
        user.password = null;
        localStorage.setItem(LocalStorage.Login, encrypt(JSON.stringify(user)));
    },

    handleGoogleLoginSuccess:  async (response : CredentialResponse) => {
        const googleUser = jwtDecode(response.credential||"") as any;

        let {data, error} = await supabase.from("User")
                                                .select()
                                                .eq("email", googleUser.email);

        if (error || !data || !data.length) {
            const pass = googleUser.email + googleUser.aud;

            const user = {
                email:googleUser.email,
                password:pass,
                internal:false,
                name:googleUser.given_name,
                familyName:googleUser.family_name
            };

            return user;
        }
        
        return data[0];
    }
}