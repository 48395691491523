import { Yards } from "./pages/Yards/Yards";
import { selectYards } from "./services/dao/Yard";
import { selectStudies } from "./services/dao/Study";
import { Studies } from "./pages/Studies/Studies";
import { Login } from "./pages/Login/Login";
import { Maintenance } from "./pages/PageNotFound/PageNotFound";
import { Navigate } from "react-router-dom";
import { Pages } from "./utils/constants";
import { Register } from "./pages/Register/Register";
import { YardSearch } from "./pages/types";

const yardPage = async () => {
    const { data } = await selectYards();
    return (data === null ? [] : data);
};

const studyPage = async () => {
    const { data } = await selectStudies();
    return (data === null ? [] : data);
};

export const getPages = async () => {
    const pages = [
        { path: "/login", component: <Login /> },
        { path: "/register", component: <Register /> },
        {
          path: "/my-yards",
          component: <Yards id={undefined} genericSearch={YardSearch.Owned} />,
        },
        {
          path: "/public-yards",
          component: <Yards id={undefined} genericSearch={YardSearch.Public} />,
        },
        {
          path: "/shared-yards",
          component: <Yards id={undefined} genericSearch={YardSearch.Shared} />,
        },
        { path: "/", component: <Navigate to={Pages.Home} /> },
        { path: "*", component: <Maintenance /> },
    ];

    const yards = await yardPage();

    if (yards) {
        yards.forEach((yard: any) => {
            pages.push({
                path: `/yard/${yard.id}`,
                component: <Yards {...yard} />,
            });
        });

        const studies = await studyPage();

        if (studies) {
            studies.forEach((study: any) => {
                pages.push({
                    path: `/study/${study.id}`,
                    component: <Studies {...study} />,
                });
            });
        }
    }

    return pages;
};