import { useState } from "react";
import { Button, Popover } from "@mui/material";
import { BreadcrumbsProps } from "../types";
import { BreadcrumbSettings } from "./BreadcrumbSettings";
import { LoginServices } from "../../services/Login";

export const Breadcrumb = ({
  title,
  isGenericYard = false,
  id = undefined,
  isYard = false,
  permissions,
}: BreadcrumbsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const clickable = (LoginServices.getLoggedUser() && isGenericYard) || permissions?.share || permissions?.edit;

  return (
    <div>
      {clickable && (
        <Button onClick={handleClick} sx={{ borderRadius: 4, mr: 3 }}>
        {title}
        </Button>
      )}
       {!clickable && title}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <BreadcrumbSettings
          id={id}
          isGenericYard={isGenericYard}
          isYard={isYard}
          permissions={permissions}
        />
      </Popover>
    </div>
  );
};
