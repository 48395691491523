import { defineMessages } from "react-intl";

export default defineMessages({
  username: {
    id: "app.pages.Login.username",
    defaultMessage: "E-mail",
  },
  password: {
    id: "app.pages.Login.password",
    defaultMessage: "Senha",
  },
  login: {
    id: "app.pages.Login.login",
    defaultMessage: "Fazer Login",
  }
});
