import { Box, Button, Typography } from "@mui/material";
import { LoginServices } from "../../services/Login";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { CustomAlert } from "../CustomAlert/CustomAlert";
import { useState } from "react";
import { requestAccess } from "../../services/AccessRequests";

export const BlockedAccess = ({itemId}:any) => {

  const [requestedAccess, setRequestedAccess] = useState("");

  const loggedUser:any = LoginServices.getLoggedUser();
  const message = loggedUser ? messages.noPermission : messages.notLoggedIn;

  const handleClick = () => {
    requestAccess(itemId)
    .then(() => setRequestedAccess("Acesso solicitado. Aguarde a aprovação."));
  }

    return (
        <>
        <Typography variant="h5" color={"GrayText"}>
          Você não tem permissão para visualizar essa página.
        </Typography>
        <Typography variant="h6" color={"GrayText"}>
          <FormattedMessage {...message}/>
        </Typography>
        {loggedUser && (
          <Box my={'5vh'}>
            <Button onClick={handleClick} variant="contained">
              Solicitar acesso
            </Button>
          </Box>
        )}

        <CustomAlert message={requestedAccess} setMessage={setRequestedAccess} severity="success" />
      </>
    );
}