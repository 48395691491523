import { Box, Grid, Tooltip, Typography, Button, Dialog, FormControl, RadioGroup, Radio, Divider, Slider, TextField, IconButton } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { DialogActions, DialogContentText, DialogContent, DialogTitle } from "@mui/material";
import { selectQuestionnaryById } from "../../services/dao/Questionnary";
import { selectQuestionsByQuestionnary } from "../../services/dao/Question";
import { selectPerformancesByItem } from "../../services/dao/Performance";
import { selectResponsesByQuestionnary, insertAllResponses } from "../../services/dao/Response";
import { insertReport } from "../../services/dao/Report";
import { LoginServices } from "../../services/Login";
import { Link } from "../../components/Link/Link";
import { useEffect, useState } from "react";
import { QuestionnaryProps } from "./types";
import { createSvgIcon } from '@mui/material/utils';
import CloseIcon from '@mui/icons-material/Close';
import "./styles.css";
import { checkPermissions } from "../../services/Permissions";
import { ItemType } from "../../pages/types";
import { Lock } from "@mui/icons-material";

function getTime(t: number) {
  var seconds = Math.floor((t / 1000) % 60);
  var minutes = Math.floor((t / 1000 / 60) % 60);
  var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  var days = Math.floor(t / (1000 * 60 * 60 * 24));

  var text_complete = "";
  if (days > 0){
      text_complete = text_complete + days + " dia";
      if (days > 1){ text_complete = text_complete + "s"; }
      text_complete = text_complete + " ";
  }

  var text_time = text_complete + ('0' + hours).slice(-2) + ":";
  text_time = text_time + ('0' + minutes).slice(-2) + ":";
  text_time = text_time + ('0' + seconds).slice(-2);

  if (hours > 0){
      text_complete = text_complete + ('0' + hours).slice(-2) + " hora";
      if (hours > 1){ text_complete = text_complete + "s"; }
      text_complete = text_complete + " ";
  }

  if (minutes > 0){
      text_complete = text_complete + ('0' + minutes).slice(-2) + " minuto";
      if (minutes > 1){ text_complete = text_complete + "s"; }
      text_complete = text_complete + " ";
  }

  if (seconds > 0){
      text_complete = text_complete + ('0' + seconds).slice(-2) + " segundo";
      if (seconds > 1){ text_complete = text_complete + "s"; }
      text_complete = text_complete + " ";
  }

  return {
    'total': t,
    'text_time': text_time,
    'text_complete': text_complete,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  };
}

function getTimeRemaining(endtime: any, starttime: any) {
  var t = Date.parse(endtime.toString()) - Date.parse(starttime.toString());
  return getTime(t);
}

function shuffle(sarray: any[], times: number) {
  if ((sarray !== undefined)&&(sarray !== null)){
    for (var i = 0; i < times; i++){
      const a = Math.floor(Math.random() * (sarray.length));
      const b = Math.floor(Math.random() * (sarray.length));
      var aux = sarray[a];
      sarray[a] = sarray[b];
      sarray[b] = aux;
    }
  }
  return sarray;
};

export const Questionnary = ({ id, title, description, tags, item, study }: QuestionnaryProps) => {
  const [open, setOpen] = useState(false);
  const [start, setStart] = useState(false);
  const [openDeadline, setOpenDeadline] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [openUserAlert, setOpenUserAlert] = useState(false);
  const [openAccessDenied, setOpenAccessDenied] = useState(false);
  const [openQuestionnaryAlert, setOpenQuestionnaryAlert] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [locked, setLocked] = useState(true);

  const [questionnary, setQuestionnary] = useState<any | null>(null);
  const [questions, setQuestions] = useState<any | null>(null);
  const [performances, setPerformances] = useState<any | null>(null);
  const [responses, setResponses] = useState<any | null>(null);
  const [user, setUser] = useState<any | null>(null);

  const [mainPage, setMainPage] = useState((<></>));
  const [errorPage, setErrorPage] = useState((<></>));
  const [reviewPage, setReviewPage] = useState((<></>));

  var bestGrade = 0.0;
  var questionIndex = 0;
  var deadline = new Date();
  var starttime = new Date();
  var timeRemaining = "";

  var resultResponses: any[];
  var currentResponse: any[];
  var currentEvaluation: any[];
  var currentReport: any[];

  var alertPage = {"evaluation": true, "response": true};
  var newResponse = {"response": "", "difficulty": -1, "relevance": -1,
                     "coherence": -1, "report": "", "resultColor": "red",
                     "textResult": "Correta", "weight": 0.0};

  useEffect(() => {
    var checkUser = true;
    var checkPerformances = true;
    var checkQuestionnary = true;
    var checkQuestions = true;
    var checkResponses = true;

    const resetChecks = async () => {
      checkUser = true;
      checkPerformances = true;
      checkQuestionnary = true;
      checkQuestions = true;
      checkResponses = true;
    };

    const fetchUser = async () => {
      const loggedUser = await LoginServices.getLoggedUser();
      if (loggedUser) { setUser(loggedUser); }
      else {
        checkUser = false;
        setUser(null);
      }
    };

    checkPermissions(item, study, ItemType.Questionnary)
      .then(permissions => setLocked(!permissions?.view));

    const fetchPerformances = async (item: number) => {
      const { data } = await selectPerformancesByItem(item);
      if (data) { setPerformances(data); }
      else {
        checkPerformances = false;
        setPerformances([]);
      }
    };

    const fetchQuestionnary = async () => {
      const { data } = await selectQuestionnaryById(id);
      if (data) {
        setQuestionnary(data);
        fetchPerformances(data[0].item);
      } else {
        checkPerformances = false;
        checkQuestionnary = false;
        setPerformances([]);
        setQuestionnary(null);
      }
    };

    const fetchQuestions = async () => {
      const { data } = await selectQuestionsByQuestionnary(id);
      if (data) { setQuestions(data); }
      else {
        checkQuestions = false;
        setQuestions([]);
      }
    };

    const fetchResponses = async () => {
      const { data } = await selectResponsesByQuestionnary(id);
      if (data) { setResponses(data); }
      else {
        checkResponses = false;
        setResponses([]);
      }
    };
      
    var attemps = 0;
    resetChecks();
    while ((attemps < 500)&&(loadData === false)){
        if ((attemps === 0)||(!checkUser)){ fetchUser(); }
        if ((attemps === 0)||(!checkQuestionnary)||(!checkPerformances)){ fetchQuestionnary(); }
        if ((attemps === 0)||(!checkQuestions)){ fetchQuestions(); }
        if ((attemps === 0)||(!checkResponses)){ fetchResponses(); }
        setLoadData((checkUser)&&(checkPerformances)&&(checkQuestionnary)&&
                    (checkQuestions)&&(checkResponses));
        attemps = attemps + 1;
    }

    setLoadData(true);
  }, [id, loadData, item, study]);

  const changeResponse = (event: any, value: any) => { newResponse["response"] = value; };
  const changeDifficulty = (event: any, value: any) => { newResponse["difficulty"] = value; };
  const changeRelevance = (event: any, value: any) => { newResponse["relevance"] = value; };
  const changeCoherence = (event: any, value: any) => { newResponse["coherence"] = value; };
  const changeReport = (value: any) => { newResponse["report"] = value; };

  const handleCloseTotal = () => {
    setOpen(false);
    setOpenQuestionnaryAlert(false);
    setStart(false);
    setLoadData(false);
    //window.location.reload();
  };

  const handleClose = () => {
    if (start){ setOpenQuestionnaryAlert(true); }
    else { handleCloseTotal(); }
  };

  const handleCloseError = () => {
      setOpenError(false);
      if (newResponse["report"] !== ""){
          var report = {"question": questions[questionIndex].id, "description": newResponse["report"]};
          if (start){
              if (currentReport !== undefined){ currentReport = currentReport.concat([report]); }
              else { currentReport = [report]; }
          } else { insertReport(report); }
          alert("Erro Reportado com Sucesso!");
      } else { alert("Nenhuma mensagem foi enviada! O erro não foi reportado!"); }
  };

  const handleCloseDeadline = () => { setOpenDeadline(false); };
  const handleCloseReview = () => { setOpenReview(false); };
  const handleCloseUserAlert = () => { setOpenUserAlert(false); };
  const handleCloseAccessDenied = () => { setOpenAccessDenied(false); };
  const handleCloseQuestionnaryAlert = () => { setOpenQuestionnaryAlert(false); };

  const PlusIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-6 -8 24 24" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 2.25v7.5m3.75-3.75h-7.5" />
    </svg>,
    'Plus',
  );

  const MinusIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="6 -10 24 24" strokeWidth={1.5} stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 7.5m3.75-3.75h-7.5" />
    </svg>,
    'Minus',
  );

  const resetSliders = () => {
      const evaluationClasses = document.querySelectorAll('.evaluation-slider');
      evaluationClasses.forEach(element_evaluation => {
          const muiSliderTrack = element_evaluation.querySelectorAll('.MuiSlider-track');
          muiSliderTrack.forEach(element_muiSliderTrack => {
              element_muiSliderTrack.setAttribute("style", "left: 0%; width: 50%;");
          });

          const muiSliderThumb = element_evaluation.querySelectorAll('.MuiSlider-thumb');
          muiSliderThumb.forEach(element_muiSliderThumb => {
              element_muiSliderThumb.setAttribute("style", "left: 50%;");
              const muiSliderThumbInput = element_muiSliderThumb.querySelectorAll('input');
              muiSliderThumbInput.forEach(element_input => {
                  element_input.setAttribute("aria-valuenow", "50");
                  element_input.setAttribute("value", "50");
              });
          });
      });

      const radioClasses = document.querySelectorAll('.MuiRadio-root');
      radioClasses.forEach(element_radio => {
          while (element_radio.classList.contains('Mui-checked')){
              element_radio.classList.remove('Mui-checked');
          }
      });
  };

  const resetResponse = () => {
      resetSliders();
      alertPage = {"evaluation": true, "response": true};
      newResponse = {"response": "", "difficulty": -1,
                     "relevance": -1, "coherence": -1,
                     "report": "", "resultColor": "red",
                     "textResult": "Incorreta",
                     "weight": 0.0};
  };

  const insertResponse = () => {
      if ((((newResponse["difficulty"] === -1)||(newResponse["relevance"] === -1)||
          (newResponse["coherence"] === -1))&&(alertPage["evaluation"] === true))||
          (newResponse["response"] === "")){
          alertPage = {"evaluation": true, "response": true};
          if ((newResponse["difficulty"] === -1)||(newResponse["relevance"] === -1)||
              (newResponse["coherence"] === -1)){ alertPage["evaluation"] = false; }
          if (newResponse["response"] === ""){ alertPage["response"] = false; }
          questionIndex = questionIndex - 1;
      } else {
          if (newResponse["response"] === questions[questionIndex].MultipleChoices.correct[0]){
              newResponse["resultColor"] = "green";
              newResponse["textResult"] = "Correta";
              newResponse["weight"] = questions[questionIndex].weight;
          }

          if ((newResponse["difficulty"] !== -1)||(newResponse["relevance"] !== -1)||
              (newResponse["coherence"] !== -1)){
              var evaluation = {"question": questions[questionIndex].id, "difficulty": newResponse["difficulty"],
                                "relevance": newResponse["relevance"], "coherence": newResponse["coherence"]};
              if (currentEvaluation !== undefined){ currentEvaluation = currentEvaluation.concat([evaluation]); }
              else { currentEvaluation = [evaluation]; }
          }

          if (newResponse["response"] !== ""){
              var insertResponse = {"question": questions[questionIndex].id, "performance": -1,
                                    "alternatives": questions[questionIndex].MultipleChoices.alternatives,
                                    "correct": questions[questionIndex].MultipleChoices.correct,
                                    "response": [newResponse["response"]], "value": newResponse["weight"]}
              if (currentResponse !== undefined){ currentResponse = currentResponse.concat([insertResponse]); }
              else { currentResponse = [insertResponse]; }
          }

          if (resultResponses !== undefined){ resultResponses = resultResponses.concat([newResponse]); }
          else{ resultResponses = [newResponse]; }
          resetResponse();
      }
  };

  const reviewQuestion = (value: any) => {
      questionIndex = value;
      setOpenReview(true);
      setReviewPage((
        <>
          <Box width="100%" position="relative" display="flex" sx={{ backgroundColor: "white" }} pb={2}>
            <Grid container display="flex" width="100%">
              <Box display="flex" width="100%">
                <Typography width="100%" fontSize="22px" align="center"><b>{questions[value].title}</b></Typography>
              </Box>
              <Box display="flex" width="100%">
                <Typography pt="15px" pb="10px" align="justify">{questions[value].MultipleChoices.description}</Typography>
              </Box>
              <FormControl fullWidth={true}><RadioGroup onChange={changeResponse}>
                {questions[value].MultipleChoices.alternatives?.length && questions[value].MultipleChoices.alternatives.map((alternative: string, index: any) => (
                  <Box display="flex" width="100%" alignItems="flex-start">
                    <Radio value={alternative} checked={resultResponses[value].response === alternative ? true : false} disabled/>
                    <Typography width="100%" pt="8px" pl="2%" align="left" color={alternative === questions[value].MultipleChoices.correct[0] ? "green" : (resultResponses[value].response === alternative ? "red" : "black")}>{alternative}</Typography>
                  </Box>
                ))}
              </RadioGroup></FormControl>
              <Box display="flex" width="100%">
                <Typography width="100%" pt="15px" pb="1%" hidden={questions[value].justification === null ? true : false} align="justify"><b>JUSTIFICATIVA: </b>{questions[value].justification}</Typography>
              </Box>
            </Grid>
          </Box>
          <Divider orientation="horizontal" flexItem />
          <Box display="flex" flexDirection="row" pt="3%" width="100%" sx={{justifyContent:'space-between'}}>
            <Button sx={{borderRadius: 6}} variant="contained" color="error" onClick={reportError}> Reportar Erro </Button>
            <Button sx={{borderRadius: 6}} variant="contained" onClick={handleCloseReview}> Terminar Revisão de Questão </Button>
          </Box>
        </>
      ));
  };

  const reviewQuestionnary = () => {
      var timeUsed = getTimeRemaining(new Date(), starttime)["text_time"];
      setStart(false);

      var totalWeight = 0.0;
      var realWeight = 0.0;
      var complete = "ponto";
      for (var i=0; i < resultResponses.length; i++){
          totalWeight = totalWeight + questions[i].weight;
          realWeight = realWeight + resultResponses[i].weight;
      }

      if (parseFloat((questionnary[0].weight*(realWeight/totalWeight)).toFixed(2)) > 1.0){ complete = "pontos"; }
      if (parseFloat((questionnary[0].weight*(realWeight/totalWeight)).toFixed(2)) > parseFloat(bestGrade.toFixed(2))){
          bestGrade = parseFloat((questionnary[0].weight*(realWeight/totalWeight)).toFixed(2));
      }

      var completeBest = "ponto";
      if (parseFloat(bestGrade.toFixed(2)) > 1.0){ completeBest = "pontos"; }

      var currentPerformance = {"item": questionnary[0].item, "weight": questionnary[0].weight,
                               "grade": parseFloat((questionnary[0].weight*(realWeight/totalWeight)).toFixed(2))};
      insertAllResponses(currentPerformance, currentResponse, currentEvaluation, currentReport);

      setMainPage((<>
          <Box width="100%" position="relative" display="flex" sx={{ backgroundColor: "white" }} pb={2}>
            <Grid container display="flex" width="100%">
              <Box display="flex" width="100%">
                <Typography width="100%" fontSize="22px" align="center"><b>Revisão: </b>{questionnary[0].Item.title}</Typography>
              </Box>
              <Box display="flex" width="100%">
                <Typography pt="15px" align="justify"><b>Descrição: </b>{questionnary[0].Item.description}</Typography>
              </Box>
              <Box display="flex" width="100%">
                <Typography pt="15px" align="justify"><b>Tempo Gasto: </b>{timeUsed}</Typography>
              </Box>
              <Box display="flex" width="100%">
                <Typography pt="1px" align="justify"><b>Pontuação: </b>{(questionnary[0].weight*(realWeight/totalWeight)).toFixed(2)} {complete}</Typography>
              </Box>
              <Box display="flex" width="100%">
                <Typography pt="1px" align="justify"><b>Melhor Pontuação: </b>{bestGrade.toFixed(2)} {completeBest}</Typography>
              </Box>
            </Grid>
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="center">Título</TableCell>
                  <TableCell align="center">Resultado</TableCell>
                  <TableCell align="center">Nota</TableCell>
                  <TableCell align="center">Revisar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resultResponses?.length && resultResponses.map((rresponses, index) => (
                  <TableRow sx={{'&:last-child td, &:last-child th': { border: 0 }}}>
                    <TableCell align="center" component="th" scope="row">{index+1}</TableCell>
                    <TableCell align="center">{questions[index].title}</TableCell>
                    <TableCell align="center" sx={{color: rresponses.resultColor}}>{rresponses.textResult}</TableCell>
                    <TableCell align="center">{((rresponses.weight/totalWeight)*questionnary[0].weight).toFixed(2)}</TableCell>
                    <TableCell align="center"><Button sx={{borderRadius: 6}} variant="contained" onClick={(e) => reviewQuestion(index)}> Revisar </Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider orientation="horizontal" sx={{pt: 3}} flexItem/>
          <Box display="flex" flexDirection="row" pt="3%" pl="34%" width="100%" sx={{justifyContent:'space-between'}}>
            <Box display="flex" width="50%">
              <Grid container display="flex" width="100%">
                <Typography width="100%" align="center" color="red">Seus resultados já</Typography>
                <Typography width="100%" align="center" color="red">foram registrados!</Typography>
              </Grid>
            </Box>
            <Button sx={{borderRadius: 6}} variant="contained" onClick={handleClose}> Finalizar Revisão </Button>
          </Box>
        </>
      ));
      //      <Button sx={{borderRadius: 6}} variant="contained" color="error" onClick={reportError}> Reportar Erro </Button>
  };

  const clickNext = () => {
      timeRemaining = getTimeRemaining(deadline, new Date())["text_time"];
      insertResponse();
      questionIndex = questionIndex + 1;
      if (questionIndex < questions.length){
          setMainPage((
            <>
              <Box width="100%" position="relative" display="flex" sx={{ backgroundColor: "white" }} pb={2}>
                <Grid container display="flex" width="100%">
                  <Box display="flex" width="100%">
                    <Typography width="100%" fontSize="22px" align="center"><b>{questions[questionIndex].title}</b></Typography>
                  </Box>
                  <Box display="flex" width="100%">
                    <Typography pt="15px" pb="10px" align="justify">{questions[questionIndex].MultipleChoices.description}</Typography>
                  </Box>
                  <FormControl fullWidth={true}><RadioGroup onChange={changeResponse}>
                    {questions[questionIndex].MultipleChoices.alternatives?.length && questions[questionIndex].MultipleChoices.alternatives.map((alternative: string, index: any) => (
                      <Box display="flex" width="100%" alignItems="flex-start">
                        <Radio value={alternative}/>
                        <Typography width="100%" pt="8px" pl="2%" align="left">{alternative}</Typography>
                      </Box>
                    ))}
                  </RadioGroup></FormControl>
                  <Typography width="100%" pt="2%" hidden={alertPage["response"]} align="center" color="red">Selecione uma das alternativas!</Typography>
                </Grid>
              </Box>
              <Divider orientation="horizontal" flexItem />
                  <Box display="flex" width="100%" pt="1%">
                    <Typography width="26%" pr="2%" align="left">Dificuldade: </Typography><MinusIcon />
                    <Slider className="evaluation-slider" defaultValue={50} min={0} max={100} onChange={changeDifficulty}/><PlusIcon />
                  </Box>
                  <Box display="flex" width="100%">
                    <Typography width="26%" pr="2%" align="left">Relevância: </Typography><MinusIcon />
                    <Slider className="evaluation-slider" defaultValue={50} min={0} max={100} onChange={changeRelevance}/><PlusIcon />
                  </Box>
                  <Box display="flex" width="100%" pb="1%">
                    <Typography width="26%" pr="2%" align="left">Coerência: </Typography><MinusIcon />
                    <Slider className="evaluation-slider" defaultValue={50} min={0} max={100} onChange={changeCoherence}/><PlusIcon />
                  </Box>
                  <Typography width="100%" pt="1%" pb="1%" hidden={alertPage["evaluation"]} align="center" color="red">Não deseja avaliar os três aspectos nesta questão?</Typography>
              <Divider orientation="horizontal" flexItem />
              <Box display="flex" flexDirection="row" pt="3%" width="100%" sx={{justifyContent:'space-between'}}>
                <Button sx={{borderRadius: 6}} variant="contained" color="error" onClick={reportError}> Reportar Erro </Button>
                <Box display="flex" width="30%">
                  <Grid container display="flex" width="100%">
                    <Typography width="100%" align="center">Questão {questionIndex+1} de {questionnary[0].quantity}</Typography>
                    <Typography width="100%" align="center">{timeRemaining} Restante</Typography>
                  </Grid>
                </Box>
                <Button sx={{borderRadius: 6}} variant="contained" onClick={clickNext}> {((questionIndex + 1) < questions.length) ? "Próxima Pergunta" : "Finalizar Questionário"} </Button>
              </Box>
            </>
          ));

          const radioClasses = document.querySelectorAll('.MuiRadio-root');
          radioClasses.forEach(element_radio => {
              while (element_radio.classList.contains('Mui-checked')){
                  element_radio.classList.remove('Mui-checked');
              }
          });
      } else { reviewQuestionnary(); }
  };

  const clickStart = () => {
      var deadline_total = getTimeRemaining(new Date(questionnary[0].deadline), new Date())["total"];
      if (deadline_total <= 0){
          setOpenDeadline(true);
          return;
      }

      if ((questions !== undefined)&&(questions !== null)){
          deadline = new Date(Date.parse((new Date()).toString()) + questionnary[0].time * 60 * 1000);
          timeRemaining = getTimeRemaining(deadline, new Date())["text_time"];
          starttime = new Date();
          setStart(true);

          var vquestions = questions;
          vquestions = shuffle(vquestions, vquestions.length*10);
          for (var i = 0; i < vquestions.length; i++){
              if ((vquestions[i].MultipleChoices.alternatives !== undefined)&&(vquestions[i].MultipleChoices.alternatives !== null)){
                  vquestions[i].MultipleChoices.alternatives = shuffle(vquestions[i].MultipleChoices.alternatives, vquestions[i].MultipleChoices.alternatives.length*10);
              }
          }

          var selectedQuestions = vquestions;
          if ((responses !== undefined)&&(responses !== null)){
              if ((vquestions.length - responses.length) >= questionnary[0].quantity){
                  for (var j = 0; j < responses.length; j++){
                      var idQuestion = -1;
                      for (var k = 0; k < selectedQuestions.length; k++){
                          if (selectedQuestions[k].id === responses[j].question){
                              idQuestion = k;
                          }
                      }
                      if (idQuestion !== -1){
                          selectedQuestions.splice(idQuestion, 1);
                      }
                  }
              }
          }

          if (selectedQuestions.length < questionnary[0].quantity){
              for (var m = 0; m < vquestions.length; m++){
                  var isSelected = false;
                  for (var n = 0; n < selectedQuestions.length; n++){
                      if (selectedQuestions[n].id === vquestions[m].id){
                          isSelected = true;
                      }
                  }
                  if (isSelected === false){
                      selectedQuestions = selectedQuestions.concat([vquestions[m]]);
                  }
              }
          }

          while (selectedQuestions.length > questionnary[0].quantity){ selectedQuestions.pop(); }
          setQuestions(selectedQuestions);
      }

      resetResponse();
      setMainPage((
        <>
          <Box width="100%" position="relative" display="flex" sx={{ backgroundColor: "white" }} pb={2}>
            <Grid container display="flex" width="100%">
              <Box display="flex" width="100%">
                <Typography width="100%" fontSize="22px" align="center"><b>{questions[questionIndex].title}</b></Typography>
              </Box>
              <Box display="flex" width="100%">
                <Typography pt="15px" pb="10px" align="justify">{questions[questionIndex].MultipleChoices.description}</Typography>
              </Box>
              <FormControl fullWidth={true}><RadioGroup onChange={changeResponse}>
                {questions[questionIndex].MultipleChoices.alternatives?.length && questions[questionIndex].MultipleChoices.alternatives.map((alternative: string, index: any) => (
                  <Box display="flex" width="100%" alignItems="flex-start">
                    <Radio value={alternative}/>
                    <Typography width="100%" pt="8px" pl="2%" align="left">{alternative}</Typography>
                  </Box>
                ))}
              </RadioGroup></FormControl>
            </Grid>
            <Typography width="100%" pt="1%" pb="1%" hidden={alertPage["response"]} align="center" color="red">Selecione uma das alternativas!</Typography>
          </Box>
          <Divider orientation="horizontal" flexItem />
              <Box display="flex" width="100%" pt="1%">
                <Typography width="26%" pr="2%" align="left">Dificuldade: </Typography><MinusIcon />
                <Slider className="evaluation-slider" defaultValue={50} min={0} max={100} onChange={changeDifficulty}/><PlusIcon />
              </Box>
              <Box display="flex" width="100%">
                <Typography width="26%" pr="2%" align="left">Relevância: </Typography><MinusIcon />
                <Slider className="evaluation-slider" defaultValue={50} min={0} max={100} onChange={changeRelevance}/><PlusIcon />
              </Box>
              <Box display="flex" width="100%" pb="1%">
                <Typography width="26%" pr="2%" align="left">Coerência: </Typography><MinusIcon />
                <Slider className="evaluation-slider" defaultValue={50} min={0} max={100} onChange={changeCoherence}/><PlusIcon />
              </Box>
              <Typography width="100%" pt="1%" pb="1%" hidden={alertPage["evaluation"]} align="center" color="red">Não deseja avaliar os três aspectos nesta questão?</Typography>
          <Divider orientation="horizontal" flexItem />
          <Box display="flex" flexDirection="row" pt="3%" width="100%" sx={{justifyContent:'space-between'}}>
            <Button sx={{borderRadius: 6}} variant="contained" color="error" onClick={reportError}> Reportar Erro </Button>
            <Box display="flex" width="30%">
              <Grid container display="flex" width="100%">
                <Typography width="100%" align="center">Questão {questionIndex+1} de {questionnary[0].quantity}</Typography>
                <Typography width="100%" align="center">{timeRemaining} Restante</Typography>
              </Grid>
            </Box>
            <Button sx={{borderRadius: 6}} variant="contained" onClick={clickNext}> Próxima Pergunta </Button>
          </Box>
        </>
      ));

      const radioClasses = document.querySelectorAll('.MuiRadio-root');
      radioClasses.forEach(element_radio => {
          while (element_radio.classList.contains('Mui-checked')){
              element_radio.classList.remove('Mui-checked');
          }
      });
  };

  const handleClickOpen = async () => {
      
      if ((user === undefined)||(user === null)){ setOpenUserAlert(true); }
      else if (locked) {setOpenAccessDenied(true);}
      else {
          if ((performances !== undefined)&&(performances !== null)){
              for (var i = 0; i < performances.length; i++){
                  if (performances[i].grade > bestGrade){ bestGrade = performances[i].grade; }
              }
          }

          var completeBest = "ponto";
          if (parseFloat(bestGrade.toFixed(2)) > 1.0){ completeBest = "pontos"; }

          setOpen(true);
          setMainPage((
              <>
                <Box width="100%" position="relative" display="flex" sx={{ backgroundColor: "white" }} pb={2}>
                  <Grid container display="flex" width="100%">
                    <Box display="flex" width="100%">
                      <Typography width="100%" fontSize="22px" align="center"><b>{questionnary[0].Item.title}</b></Typography>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography pt="15px" align="justify"><b>Descrição: </b>{questionnary[0].Item.description}</Typography>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography pt="15px" align="justify"><b>Tags: </b>{questionnary[0].Item.tags.join(', ')}</Typography>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography pt="1px" align="justify"><b>Tentativas: </b>{performances.length} / {questionnary[0].attempts}</Typography>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography pt="1px" align="justify"><b>Pontuação: </b>{questionnary[0].weight} pontos</Typography>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography pt="1px" align="justify"><b>Quantidade de Questões: </b>{questionnary[0].quantity}</Typography>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography pt="1px" align="justify"><b>Tempo Máximo do Questionário: </b>{getTime(questionnary[0].time * 60 * 1000)["text_complete"]}</Typography>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography pt="1px" align="justify"><b>Disponível até: </b>{(new Date(questionnary[0].deadline)).toLocaleString("pt-BR").replace(",", "")}</Typography>
                    </Box>
                    <Box display="flex" width="100%">
                      <Typography pt="1px" align="justify"><b>Melhor Pontuação: </b>{bestGrade.toFixed(2)} {completeBest}</Typography>
                    </Box>
                  </Grid>
                </Box>
                <Box display="flex" flexDirection="row" pt="10px" width="100%" sx={{justifyContent:'space-between'}}>
                  <Button sx={{borderRadius: 6}} variant="contained" disabled={(performances.length >= questionnary[0].attempts)} onClick={clickStart}> Começar Questionário </Button>
                  <Box display="flex" width="40%">
                    <Typography pt="1px" align="center" hidden={(performances.length < questionnary[0].attempts)} color="red">Quantidade Máxima de Tentativas Atingida!</Typography>
                  </Box>
                </Box>
              </>
          ));
      }
  };

  const reportError = () => {
      setErrorPage((
        <>
          <Box width="100%" position="relative" display="flex" sx={{ backgroundColor: "white" }} pb={1}>
            <Grid container display="flex" width="100%">
              <Box display="flex" width="100%" pb="3%">
                <Typography width="100%" fontSize="22px" align="center"><b>Reportar Erro</b></Typography>
              </Box>
              <TextField fullWidth label="Reportar Erro" multiline rows={10} onChange={(e) => changeReport(e.target.value)}/>
              <Box display="flex" flexDirection="row" pt="3%" width="100%" sx={{justifyContent:'center'}}>
                <Button sx={{borderRadius: 6}} variant="contained" onClick={handleCloseError}> Enviar Erro </Button>
              </Box>
            </Grid>
          </Box>
        </>
      ));
      setOpenError(true);
  };  

  return (
    <div>
      <Box sx={{width: { xs: 240, md: 260 }, height: { xs: 200, md: 225 }}} className="content-box">
        <Link onClick={handleClickOpen} target="_blank" style={{ height: "100%" }}>
          <Box p={2}>
            <Box width="100%" maxHeight="45px" display="flex" position="relative" overflow="hidden" fontWeight="bold" justifyContent="space-between">
              <Tooltip title={title}>
                <Typography width="100%" variant="subtitle2" className="content-name" align="center">{title}</Typography>
              </Tooltip>
              {locked && (<Lock scale={"small"} sx={{ color: "#919191" }} />)}
            </Box>
            <Box width="100%" py={2} maxHeight={{ xs: "80px", md: "100px" }} display="flex" position="relative" overflow="hidden">
              <Typography width="100%" variant="body2" color="text.secondary" align="center">{description}</Typography>
            </Box>
            <Box py={2} maxHeight="70px" display="inline" overflow="hidden">
              {tags?.length &&
                tags.map((tag, index) => (
                  <Box key={index} display="inline-block" border={1} borderColor="white" borderRadius={3} mr={2} my={0.5} p={0.5} sx={{ backgroundColor: "lightgreen" }}>
                    <Typography variant="body2">{tag}</Typography>
                  </Box>
                ))}
            </Box>
          </Box>
        </Link>
      </Box>
      <Dialog open={openUserAlert} onClose={handleCloseUserAlert} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <IconButton aria-label="close" onClick={handleCloseUserAlert} sx={{position: 'absolute', right: 0, top: 0, color: (theme) => theme.palette.grey[500],}}><CloseIcon /></IconButton>
        <DialogTitle id="alert-dialog-title" align="left">Realize Login</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align="left">
            Para responder este questionário é necessário realizar login!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button href="/login" autoFocus>Fazer Login</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAccessDenied} onClose={handleCloseAccessDenied} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <IconButton aria-label="close" onClick={handleCloseAccessDenied} sx={{position: 'absolute', right: 0, top: 0, color: (theme) => theme.palette.grey[500],}}><CloseIcon /></IconButton>
        <DialogTitle id="alert-dialog-title" align="left">Sem Acesso</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align="left">
            Para responder este questionário, é necessário que ele seja compartilhado com você!
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog open={openQuestionnaryAlert} onClose={handleCloseQuestionnaryAlert} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" align="left">Deseja Desistir do Questionário?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align="left">
            Caso você feche o questionário, irá perder todo o progresso atingido até o momento nessa tentativa! Os pontos não serão computados!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseQuestionnaryAlert}>Continuar Questionário</Button>
          <Button onClick={handleCloseTotal} autoFocus>Desistir</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeadline} onClose={handleCloseDeadline} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" align="left">Este Questionário está Indisponível!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align="left">A data limite deste questionário foi atingida!</DialogContentText>
          <DialogContentText id="alert-dialog-description" align="left">Não são mais aceitas respostas!</DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog id="questionnaryReportError" open={openError}>
        <IconButton aria-label="close" onClick={handleCloseError} sx={{position: 'absolute', right: 0, top: 0, color: (theme) => theme.palette.grey[500],}}><CloseIcon /></IconButton>
        <Box display="flex" width="100%" maxHeight="100%" alignItems="center" flexDirection="column" p={3} pb={2}>
          <Box display="flex" width="100%" maxHeight="100%" alignItems="center" flexDirection="column" border={1} borderColor="lightgray" borderRadius={3} p={3} pb={2} m={1}>
            {errorPage}
          </Box>
        </Box>
      </Dialog>
      <Dialog id="questionnaryReview" open={openReview}>
        <IconButton aria-label="close" onClick={handleCloseReview} sx={{position: 'absolute', right: 0, top: 0, color: (theme) => theme.palette.grey[500],}}><CloseIcon /></IconButton>
        <Box display="flex" width="100%" maxHeight="100%" alignItems="center" flexDirection="column" p={3} pb={2}>
          <Box display="flex" width="100%" maxHeight="100%" alignItems="center" flexDirection="column" border={1} borderColor="lightgray" borderRadius={3} p={3} pb={2} m={1}>
            {reviewPage}
          </Box>
        </Box>
      </Dialog>
      <Dialog id="questionnaryDialog" open={((open)&&((user !== undefined)&&(user !== null)))}>
        <IconButton aria-label="close" onClick={handleClose} sx={{position: 'absolute', right: 0, top: 0, color: (theme) => theme.palette.grey[500],}}><CloseIcon /></IconButton>
        <Box display="flex" width="100%" maxHeight="100%" alignItems="center" flexDirection="column" p={3} pb={2}>
          <Box display="flex" width="100%" maxHeight="100%" alignItems="center" flexDirection="column" border={1} borderColor="lightgray" borderRadius={3} p={3} pb={2} m={1}>
            {mainPage}
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
