import { LoginServices } from "../Login";
import supabase from "./supabaseClient";

export const insertItem = async (item: any) => {
  const loggedUser = await LoginServices.getLoggedUser();
  if (loggedUser !== undefined && loggedUser !== null) {
    item["creator"] = loggedUser.id;
    if (!item["visibility"]) {
      item["visibility"] = "public";
    }
    const { data, error } = await supabase
      .from("Item")
      .insert([item])
      .select("id");
    if (error) {
      console.error("[insertItem] Error: " + error.message);
      return null;
    }
    return data[0].id;
  }
  return null;
};

export const insertItems = async (items: any) => {
  const loggedUser = await LoginServices.getLoggedUser();
  if (loggedUser !== undefined && loggedUser !== null) {
    for (var i = 0; i < items.length; i++) {
      items[i]["creator"] = loggedUser.id;
      if (!items[i]["visibility"]) {
        items[i]["visibility"] = "public";
      }
    }

    const { data, error } = await supabase
      .from("Item")
      .insert(items)
      .select("id");

    if (error) {
      console.error("[insertItems] Error: " + error.message);
      return null;
    }
    return { data };
  }
  return null;
};

export const selectItems = async () => {
  const loggedUser = await LoginServices.getLoggedUser();
  if (loggedUser !== undefined && loggedUser !== null) {
    const { data, error } = await supabase
      .from("Item")
      .select()
      .eq("creator", loggedUser.id);
    if (error) {
      console.error("[selectItems] Error: " + error.message);
    }
    return { data };
  } else {
    const response = null;
    return { response };
  }
};

export const selectAllItems = async () => {
  const { data, error } = await supabase.from("Item").select();
  if (error) {
    console.error("[selectAllItems] Error: " + error.message);
  }
  return { data };
};

export const selectItemById = async (id: any) => {
  const { data, error } = await supabase.from("Item").select().eq("id", id);
  if (error) {
    console.error("[selectItemById] Error: " + error.message);
  }
  return { data };
};

export const selectItemByVariable = async (variable: string, value: any) => {
  const { data, error } = await supabase
    .from("Item")
    .select()
    .eq(variable, value);
  if (error) {
    console.error("[selectItemByVariable] Error: " + error.message);
  }
  return { data };
};

export const updateItem = async (item: any) => {
  const { error } = await supabase.from("Item").update(item).eq("id", item.id);
  if (error) {
    console.error("[updateItem] Error: " + error.message);
    return false;
  } else {
    return true;
  }
};

export const deleteItem = async (id: any) => {
  const { error } = await supabase.from("Item").delete().eq("id", id);
  if (error) {
    console.error("[deleteItem] Error: " + error.message);
    return false;
  } else {
    return true;
  }
};
