import { useState } from "react";
import { Button, Dialog, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import DeleteIcon from "@mui/icons-material/Delete";
import { RemoveItem } from "../CardSettings/RemoveItem";
import { CardSettingsProps } from "../Card/types";
import { NewCardDialog } from "../../containers/NewCard/NewCardDialog";
import { Sharing } from "../../containers/Sharing";
import { ItemType } from "../../pages/types";

export const BreadcrumbSettings = ({
  id,
  isGenericYard,
  isYard,
  permissions,
}: CardSettingsProps) => {
  const [openRemoveDrawer, setRemoveDrawerOpen] = useState(false);
  const [openNewCardDialog, setNewCardDialogOpen] = useState(false);
  const [openEditCardDialog, setEditCardDialogOpen] = useState(false);
  const [openShareDialog, setShareDialogOpen] = useState(false);

  const handleClickOpen = (text: string) => {
    if (text === "Novo Yard" || text === "Novo Estudo")
      setNewCardDialogOpen(true);
    if (text === "Remover") setRemoveDrawerOpen(true);
    if (text === "Editar") setEditCardDialogOpen(true);
    if (text === "Compartilhar") setShareDialogOpen(true);
  };

  const handleClose = () => {
    setRemoveDrawerOpen(false);
    setNewCardDialogOpen(false);
    setEditCardDialogOpen(false);
    setShareDialogOpen(false);
  };

  const newCard = {
    text: isGenericYard ? "Novo Yard" : "Novo Estudo",
    icon: <AddIcon />,
    optionClick: (
      <Dialog open={openNewCardDialog} onClose={handleClose}>
        <NewCardDialog
          id={id}
          isGenericYardPage={isGenericYard}
          handleClose={handleClose}
        />
      </Dialog>
    ),
    hidden: !isGenericYard && !permissions?.edit,
  };

  const editCard = {
    text: "Editar",
    icon: <EditIcon />,
    optionClick: (
      <Dialog open={openEditCardDialog} onClose={handleClose}>
        <NewCardDialog
          id={id}
          isGenericYardPage={isYard}
          handleClose={handleClose}
          isEditCard
          isYard={isYard}
        />
      </Dialog>
    ),
    hidden: !permissions?.edit,
  };

  const removeCard = {
    text: "Remover",
    icon: <DeleteIcon />,
    optionClick: (
      <Dialog open={openRemoveDrawer} onClose={handleClose}>
        <RemoveItem isYard={isYard} id={id} closeDialog={handleClose} />
      </Dialog>
    ),
    hidden: !permissions?.edit,
  };

  const shareCard = {
      text: "Compartilhar",
      icon: <PersonAddAlt1Icon />,
      optionClick: (
        <Sharing
          open={openShareDialog} 
          setOpen={setShareDialogOpen}
          id={id}
          itemType={isYard ? ItemType.Yard : ItemType.Study}  />
      ),
      hidden: !permissions?.share,
  };

  const yardOptions = isGenericYard
    ? [newCard]
    : [
        newCard,
        shareCard,
        editCard,
        removeCard,
      ];

  const studyOptions = [
    shareCard,
    editCard,
    removeCard,
  ];

  const options = isYard ? yardOptions : studyOptions;

  return (
    <>
      {options.map(({ text, icon, optionClick, hidden }, index) => {
        return (
          <div key={index}>
            {!hidden && (
              <>
                <Button
                  onClick={() => handleClickOpen(text)}
                  sx={{
                    display: "flex",
                    px: 2,
                    alignItems: "center",
                    color: "black",
                  }}
                >
                  {icon}
                  <Typography sx={{ p: 2 }}>{text}</Typography>
                </Button>
                {optionClick}
              </>
            )}
          </div>
        );
      })}
    </>
  );
};
