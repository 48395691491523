import { LoginServices } from "../Login";
import supabase from "./supabaseClient";

export const insertSharing = async (sharing: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        if (!sharing["user"]) {sharing["user"] = loggedUser.id;}
        const { data, error } = await supabase.from("Sharing").insert([sharing]).select("id");
        if (error) {
            console.error("[insertSharing] Error: "+error.message);
            return null;
        } else { return data[0].id; }
    } else { return null; }
};

export const insertSharings = async (sharings: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        const { data, error } = await supabase.from("Sharing").insert(sharings).select("id");
        if (error) { console.error("[insertSharings] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const selectSharings = async () => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        const { data, error } = await supabase.from("Sharing").select().eq("user", loggedUser.id);
        if (error) { console.error("[selectSharings] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const selectAllSharings = async () => {
    const { data, error } = await supabase.from("Sharing").select();
    if (error) { console.error("[selectAllSharings] Error: "+error.message); }
    return { data };
};

export const selectSharingId = async (item: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        const { data, error } = await supabase.from("Sharing").select()
                                              .eq("user", loggedUser.id)
                                              .eq("item", item);
        if (error) {
            console.error("[selectSharingId] Error: "+error.message);
            return null;
        } else {
            if (data.length > 0){ return data[0].id; }
            else { return null; }
        }
    } else { return null; }
};

export const selectSharingById = async (id: any) => {
    const { data, error } = await supabase.from("Sharing").select().eq("id", id);
    if (error) { console.error("[selectSharingById] Error: "+error.message); }
    return { data };
};

export const selectSharingByVariable = async (variable: string, value: any) => {
    const { data, error } = await supabase.from("Sharing").select().eq(variable, value);
    if (error) { console.error("[selectSharingByVariable] Error: "+error.message); }
    return { data };
};

export const updateSharing = async (sharing: any, id: any) => {
    const { error } = await supabase.from("Sharing").update(sharing).eq("id", id);
    if (error) {
        console.error("[updateSharing] Error: "+error.message);
        return false;
    } else { return true; }
};

export const deleteSharing = async (id: any) => {
    const { error } = await supabase.from("Sharing").delete().eq("id", id);
    if (error) {
        console.error("[deleteSharing] Error: "+error.message);
        return false;
    } else { return true; }
};