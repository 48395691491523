import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { Link } from "../Link/Link";
import {
  FolderOpenOutlined,
  Lock,
  OpenInNew
} from "@mui/icons-material";
import { getContents } from "../../services/dao/Content";
import { useEffect, useState } from "react";
import { ContentCardProps, LoadContentCardProps } from "./types";
import "./styles.css";
import { checkPermissions } from "../../services/Permissions";
import { ItemType, PermissionProps } from "../../pages/types";
import { RequestAccessButton } from "../RequestAccessButton";

const ContentCard = ({
  title,
  description,
  tags,
  url,
  item,
  study,
}: ContentCardProps) => {
  const [permissions, setPermissions] = useState<PermissionProps | null>(null);

  useEffect(() => {
    checkPermissions(item, study, ItemType.Content).then(setPermissions);
  }, [item, study]);

  const component = (
    <Box p={2}>
      <Box
        maxHeight="45px"
        display="flex"
        position="relative"
        overflow="hidden"
        fontWeight="bold"
        justifyContent="space-between"
      >
        <Tooltip title={title}>
          <Typography
            variant="subtitle2"
            width="100%"
            className="content-name"
            align="center"
          >
            {title}
          </Typography>
        </Tooltip>
        {permissions?.view ? (
          <OpenInNew
            sx={{
              fontSize: "1.2rem",
              marginRight: "0.5rem",
              color: "#919191",
            }}
          />
        ) : (
          <Lock scale={"small"} sx={{ color: "#919191" }} />
        )}
      </Box>
      <Box
        py={2}
        maxHeight={{ xs: "80px", md: "100px" }}
        display="flex"
        position="relative"
        overflow="hidden"
      >
        <Typography variant="body2" color="text.secondary">
          {" "}
          {description}{" "}
        </Typography>
      </Box>
      <Box py={2} maxHeight="70px" display="inline" overflow="hidden">
        {tags?.length &&
          tags.map((tag, index) => (
            <Box
              key={index}
              display="inline-block"
              border={1}
              borderColor="white"
              borderRadius={3}
              mr={2}
              my={0.5}
              p={0.5}
              sx={{ backgroundColor: "lightgreen" }}
            >
              <Typography variant="body2">{tag}</Typography>
            </Box>
          ))}
      </Box>
      {!permissions?.view && false && ( //compartilhamento de conteúdo ainda nao existe
        <RequestAccessButton itemId={item.id} permissions={permissions} />
      )}
    </Box>
  );

  return (
    <Box
      sx={{ width: { xs: 240, md: 260 }, height: { xs: 200, md: 225 } }}
      position={"relative"}
      className={permissions?.view ? "content-box" : "blocked-content-box"}
    >
      {permissions?.view ? (
        <Link href={url} target="_blank" style={{ height: "100%" }}>
          {component}
        </Link>
      ) : (
        component
      )}
    </Box>
  );
};

export const LoadContentCards = ({ study }: LoadContentCardProps) => {
  const [contents, setContents] = useState<any[]>([]);

  useEffect(() => {
    const fetchContents = async () => {
      const { data } = await getContents(study);
      if (data) {
        setContents(data);
      }
    };

    fetchContents();
  }, [study]);

  return (
    <>
      {!!contents.length && (
        <Grid container columnSpacing={3} rowSpacing={3}>
          {contents.map((content, index) => (
            <Grid item key={index}>
              <ContentCard
                id={content.id}
                title={content.Item.title}
                description={content.Item.description}
                tags={content.Item.tags}
                url={content.url}
                item={content.Item}
                study={study}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {!contents.length && (
        <Box sx={{ color: "#0000008a", marginBottom: "2rem" }}>
          <FolderOpenOutlined sx={{ fontSize: "4rem" }} />
          <Typography variant="h6">
            Ainda não há nada para ver por aqui.
          </Typography>
        </Box>
      )}
    </>
  );
};
