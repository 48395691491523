import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { YardCard } from "../components/Card";
import { selectStudiesByYard } from "./dao/Study";
import { selectYardByAdvancedSearch } from "./dao/Yard";

export const LoadCards = ({ isYard, id, yardPermissions, yardSearch }) => {
  const [content, setContent] = useState(null);

  const isGenericYardPage = id === undefined;

  useEffect(() => {
    const fetchContent = ({data}) => setContent(data === null ? [] : data);
    if (isYard) {
       selectYardByAdvancedSearch(yardSearch).then(fetchContent);
    }
    else {
      selectStudiesByYard(id).then(fetchContent);
    }  

  }, [isYard, yardSearch, id]);

  return (
    <Grid container rowSpacing={3} columnSpacing={3} className="Cards">
      {content &&
        content.map(({ Item, yard, id }, index) => (
          <Grid item key={index}>
            <YardCard
              title={Item.title}
              description={Item.description}
              tags={Item.tags == null ? [] : Item.tags}
              id={id}
              isGenericYardPage={isGenericYardPage}
              yard={yard}
              item={Item}
              yardPermissions={yardPermissions}
            />
          </Grid>
        ))}
    </Grid>
  );
};