import { Password } from "../utils/constants";

const validateRequired = (value: string) => {
  if (!value) {
    throw new Error("Este campo é obrigatório.");
  }

  return true;
};

export const LoginValidationServices = {
  validateRequired: validateRequired,

  validateEmail: (email: string, isRequired: boolean) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (isRequired) {
      validateRequired(email);
    }

    if (!!email && !emailRegex.test(email)) {
      throw new Error("Email inválido.");
    }

    return true;
  },

  validatePassword: (password: string, validateStrength: boolean) => {
    validateRequired(password);

    if (validateStrength) {
      const strongPasswordRegex = /(?=.*[!@#$&*])(?=.*\d).*/;
      if (
        password.length < Password.MinLength ||
        password.length > Password.MaxLength ||
        !strongPasswordRegex.test(password)
      ) {
        throw new Error("Senha inválida.");
      }
    }

    return true;
  },
};
