import { LoginServices } from "../Login";
import supabase from "./supabaseClient";

export const insertReport = async (report: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        report["user"] = loggedUser.id;
        const { data, error } = await supabase.from("Report").insert([report]).select("id");
        if (error) {
          console.error("[insertReport] Error: "+error.message);
          return null;
        } else { return data[0].id; }
    } else { return null; }
};

export const insertReports = async (reports: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        for (var i = 0; i < reports.length; i++){ reports[i]["user"] = loggedUser.id; }
        const { data, error } = await supabase.from("Report").insert(reports).select("id");
        if (error) { console.error("[insertReports] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const selectReports = async () => {
    const loggedUser = await LoginServices.getLoggedUser();
    if ((loggedUser !== undefined)&&(loggedUser !== null)){
        const { data, error } = await supabase.from("Report").select().eq("user", loggedUser.id);
        if (error) { console.error("[selectReports] Error: "+error.message); }
        return { data };
    } else {
        const response = null;
        return { response };
    }
};

export const selectAllReports = async () => {
    const { data, error } = await supabase.from("Report").select();
    if (error) { console.error("[selectAllReports] Error: "+error.message); }
    return { data };
};

export const selectReportById = async (id: any) => {
    const { data, error } = await supabase.from("Report").select().eq("id", id);
    if (error) { console.error("[selectReportById] Error: "+error.message); }
    return { data };
};

export const selectReportByVariable = async (variable: string, value: any) => {
    const { data, error } = await supabase.from("Report").select().eq(variable, value);
    if (error) { console.error("[selectReportByVariable] Error: "+error.message); }
    return { data };
};

export const updateReport = async (report: any, id: any) => {
    const { error } = await supabase.from("Report").update(report).eq("id", id);
    if (error) {
        console.error("[updateReport] Error: "+error.message);
        return false;
    } else { return true; }
};

export const deleteReport = async (id: any) => {
    const { error } = await supabase.from("Report").delete().eq("id", id);
    if (error) {
        console.error("[deleteReport] Error: "+error.message);
        return false;
    } else { return true; }
};