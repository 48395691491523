import { Box, Grid, Typography } from "@mui/material";
import { FolderOpenOutlined } from "@mui/icons-material";
import { selectQuestionnariesByStudy } from "../../services/dao/Questionnary";
import { Questionnary } from "../../containers/Questionnary";
import { useEffect, useState } from "react";
import { LoadQuestionnaryCardProps } from "./types";
import "./styles.css";

export const LoadQuestionnaryCards = ({ study }: LoadQuestionnaryCardProps) => {
  const [questionnaries, setQuestionnaries] = useState<any[]>([]);

  useEffect(() => {
    const fetchQuestionnaries = async () => {
      const { data } = await selectQuestionnariesByStudy(study);
      if (data){ setQuestionnaries(data); }
    };

    fetchQuestionnaries();
  }, [study]);

  return (
    <>
      {!!questionnaries.length && (
        <Grid container columnSpacing={3} rowSpacing={3}>
          {questionnaries.map((questionnary, index) => (
            <Grid item key={index}>
              <Questionnary
                id={questionnary.id}
                title={questionnary.Item.title}
                description={questionnary.Item.description}
                tags={questionnary.Item.tags}
                item={questionnary.Item}
                study={study}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {!questionnaries.length && (
        <Box sx={{ color: "#0000008a", marginBottom: "2rem" }}>
          <FolderOpenOutlined sx={{ fontSize: "4rem" }} />
          <Typography variant="h6">
            Ainda não há nada para ver por aqui.
          </Typography>
        </Box>
      )}
    </>
  );
};
