import {
  Button,
  CircularProgress,
  Dialog,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useCallback, useReducer, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CopyLink } from "./CopyLink";
import { FileUpload } from "./FileUpload";
import { FreeText } from "./FreeText";
import { COPY_LINK, FILE_UPLOAD, FREE_TEXT } from "./constants";
import messages from "./messages";
import { StudiesProps } from "../../pages/types";
import { RequiredTextField } from "../../components/RequiredTextField/RequiredTextField";
import { CustomAlert } from "../../components/CustomAlert/CustomAlert";
import { ValidationInputHandle } from "../../components/types";
import { handleUpload } from "../../services/dao/Content";
import { ContentUploadOptionHandle } from "./types";

const tabs = [
  { title: messages.fileUpload, id: FILE_UPLOAD },
  { title: messages.copyLink, id: COPY_LINK },
  { title: messages.freeText, id: FREE_TEXT },
];

export const NewContent = ({ id, permissions }: StudiesProps) => {
  const [open, setOpen] = useState(false);

  const fileUploadRef = useRef<ContentUploadOptionHandle>(null);
  const copyLinkRef = useRef<ContentUploadOptionHandle>(null);
  const freeTextRef = useRef<ContentUploadOptionHandle>(null);

  const titleRef = useRef<ValidationInputHandle>(null);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [error, setError] = useState("");

  function reducer(
    state: React.RefObject<ContentUploadOptionHandle>,
    action: string
  ) {
    switch (action) {
      case FILE_UPLOAD:
        return fileUploadRef;
      case COPY_LINK:
        return copyLinkRef;
      case FREE_TEXT:
        return freeTextRef;
      default:
        return state;
    }
  }

  const [selectedTabRef, dispatch] = useReducer(reducer, fileUploadRef);

  const handleTabsChange = useCallback(
    (tabId: string) => () => {
      dispatch(tabId);
    },
    []
  );

  const handleClickOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setTitle("");
    setDescription("");
    setTags("");
    setError("");
    setIsPublic(true);
    setOpen(false);
  };

  const submitContent = async () => {
    setLoading(true);

    try {
      const params: any = selectedTabRef.current
        ? selectedTabRef.current.buildContent()
        : {};

      params.studyId = id;
      params.title = title;
      params.description = description;
      params.tags = tags;
      params.visibility = isPublic ? "public" : "restricted";

      if (!titleRef.current || !titleRef.current.validateComponent()) {
        return;
      }

      if (
        selectedTabRef.current &&
        selectedTabRef.current.validateComponent()
      ) {
        await handleUpload(params);
      }
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {permissions?.edit && (
        <div>
          <Button variant="contained" onClick={handleClickOpen}>
            <Typography>Novo conteúdo</Typography>
          </Button>
          <Dialog
            open={open}
            PaperProps={{
              onClick: (e: React.MouseEvent) => e.stopPropagation(),
            }}
            onClose={(event) => handleClose(event)}
            onFocus={(event) => event.stopPropagation()}
          >
            <Box
              display="flex"
              width="500px"
              alignItems="center"
              flexDirection="column"
              border={1}
              borderColor="lightgray"
              borderRadius={3}
              p={2}
              pb={1}
              m={1}
            >
              <Box py={1} width={"100%"}>
                <RequiredTextField
                  ref={titleRef}
                  variant="standard"
                  label="Título"
                  value={title}
                  setValue={setTitle}
                  fullWidth
                />
              </Box>
              <Box py={1} width={"100%"}>
                <TextField
                  label="Descrição"
                  variant="standard"
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Box>
              <Box py={1} width={"100%"}>
                <TextField
                  label="Tags"
                  variant="standard"
                  fullWidth
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                />
              </Box>
              <Box py={1} width={"100%"}>
                 <FormControlLabel
                  control={<Switch
                    checked={isPublic}
                    onChange={(e) => setIsPublic(e.target.checked)}
                  />}
                  label="Público"
                  labelPlacement="end"
                />
              </Box>
              <Box
                width="100%"
                position="relative"
                display="flex"
                sx={{
                  backgroundColor: "white",
                }}
                pb={2}
                py={1}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  {tabs.map(({ title, id }, index) => (
                    <Grid
                      item
                      key={index}
                      xs={12}
                      md={4}
                      py={1}
                      justifyContent="center"
                      display="flex"
                      border={1}
                      borderColor="lightgray"
                      sx={{ cursor: "pointer" }}
                      borderRadius={1}
                      onClick={handleTabsChange(id)}
                    >
                      <FormattedMessage {...title} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {selectedTabRef === fileUploadRef && (
                <FileUpload ref={fileUploadRef} />
              )}
              {selectedTabRef === copyLinkRef && <CopyLink ref={copyLinkRef} />}
              {selectedTabRef === freeTextRef && <FreeText ref={freeTextRef} />}
              {!loading && selectedTabRef !== freeTextRef && (
                <Button
                  sx={{ my: 2 }}
                  variant="contained"
                  onClick={submitContent}
                >
                  Criar conteúdo
                </Button>
              )}
              {loading && <CircularProgress sx={{ my: 2 }} />}
            </Box>
          </Dialog>
          <CustomAlert message={error} setMessage={setError} severity="error" />
        </div>
      )}
    </>
  );
};
