import { YardSearch } from "../../pages/types";
import { LoginServices } from "../Login";
import { insertItem, updateItem } from "./Item";
import supabase from "./supabaseClient";

export const insertYard = async (yard: any) => {
  const itemId = await insertItem(yard.Item);
  if (itemId !== null) {
    delete yard["Item"];
    yard["item"] = itemId;
    const { data, error } = await supabase
      .from("Yard")
      .insert([yard])
      .select("id");
    if (error) {
      console.error("[insertYard] Error: " + error.message);
      return null;
    } else {
      return data[0].id;
    }
  } else {
    return null;
  }
};

export const insertYards = async (yards: any) => {
  for (var i = 0; i < yards.length; i++) {
    var itemId = await insertItem(yards[i]["Item"]);
    delete yards[i]["Item"];
    if (itemId !== null) {
      yards[i]["item"] = itemId;
    }
  }

  const { data, error } = await supabase
    .from("Yard")
    .insert(yards)
    .select("id");
  if (error) {
    console.error("[insertYards] Error: " + error.message);
  }
  return { data };
};

export const selectYards = async () => {
    const { data, error } = await supabase
      .from("Yard")
      .select("*, Item!inner(*, Sharing(*))")
      .eq("Item.active", true);
    if (error) {
      console.error("[selectYards] Error: " + error.message);
    }
    return { data };
};

export const selectAllYards = async () => {
  const { data, error } = await supabase
    .from("Yard")
    .select("*, Item!inner(*, Sharing(*))");
  if (error) {
    console.error("[selectAllYards] Error: " + error.message);
  }
  return { data };
};

export const selectYardById = async (id: any) => {
  const { data, error } = await supabase
    .from("Yard")
    .select("*, Item!inner(*, Sharing(*))")
    .eq("id", id);
  if (error) {
    console.error("[selectYardById] Error: " + error.message);
  }
  return { data };
};

export const selectYardByVariable = async (variable: string, value: any) => {
  var { data, error } = await supabase
    .from("Yard")
    .select("*, Item!inner(*, Sharing(*))")
    .eq(variable, value)
    .eq("Item.active", true);
  if (error) {
    console.error("[selectYardByVariable] Error: " + error.message);
  } else {
    if (data) {
      for (var i = data.length - 1; i >= 0; i--) {
        if (data[i]["Item"] === null) {
          delete data[i];
        }
      }

      if (variable === "Item.Sharing.user"){
        for (i = data.length - 1; i >= 0; i--) {
          if (data[i]["Item"]["Sharing"].length === 0) {
            delete data[i];
          }
        }
      }
    }
  }
  return { data };
};

export const selectYardByAdvancedSearch = async (yardSearch: YardSearch) => {
  const loggedUser = await LoginServices.getLoggedUser();

  if (yardSearch !== YardSearch.Public && !loggedUser) {
    return {data:null};
  }

  const endpoints = {} as any;
  endpoints[`${YardSearch.Public}`] = "public";
  endpoints[`${YardSearch.Owned}`] = "owned";
  endpoints[`${YardSearch.Shared}`] = "shared";

  if (endpoints[`${yardSearch}`] === "public"){
    var { data, error } = await supabase.from("Yard").select("*, Item!inner(*, Sharing(*))")
                                        .neq("Item.visibility", "private")
                                        .eq("Item.active", true);
    if (error) { console.error("[selectYardByVariable] Error: " + error.message);
    } else {
      if (data) {
        for (var i = data.length - 1; i >= 0; i--) {
          if (data[i]["Item"] === null) { delete data[i]; }
        }
      }
    }
    return { data };
  }

  else if (endpoints[`${yardSearch}`] === "owned"){
    const response = await selectYardByVariable("Item.creator", loggedUser.id);
    return response;
  }

  else {
    const response = await selectYardByVariable("Item.Sharing.user", loggedUser.id);
    return response;
  }
};

export const updateYard = async (yard: any) => {
  const { data } = await selectYardById(yard.id);

  if (!data) {
    throw new Error("Yard não encontrado para o processo de atualização.");
  }

  const currentItem = data[0].Item;
  currentItem.title = yard.Item.title;
  currentItem.description = yard.Item.description;
  currentItem.tags = yard.Item.tags;
  delete currentItem.Sharing;

  await updateItem(currentItem);
};

export const deleteYard = async (id: any) => {
    const { data } = await selectYardById(id);

    if (!data) {
      throw new Error("Yard não pode ser removido no momento.");
    }
    
    const currentItem = data[0].Item;
    currentItem.active = false;
    delete currentItem.Sharing;
  
    return await updateItem(currentItem);
};
