import { encrypt } from "../utils/Encryption";
import { LoginServices } from "./Login";
import supabase from "./dao/supabaseClient";

export const RegisterServices = {

    createAccount: async (user:any, internal:boolean) => {        
        
        const {data} = await supabase.from("User")
        .select()
        .eq("email", user.email);
        
        if (data && data.length) {
            throw new Error("Email já cadastrado.");
        }
        
        var decryptedPassword = user.password;
        user.password = encrypt(user.password);
        user.internal = internal;
        user.confirmed = true;
        
        const { error } = await supabase.from("User")
                                .insert([user]);
            
        if (error) {
            user.password = decryptedPassword;
            console.error(error.message);
            throw new Error("Houve um erro ao criar conta. Tente novamente mais tarde!");
        }

        LoginServices.handleLoginSuccess(user);
    }
};