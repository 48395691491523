import { Box, Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../utils/constants";

export const Maintenance = () => {
  const navigate = useNavigate();

  const handleReturnHomeClick = () => navigate(Pages.Home);

  return (
    <Box
      height={"100vh"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
    >
      <Typography variant="h1">OOOPS!</Typography>
      <Typography variant="h5">
        <FormattedMessage {...messages.title} />{" "}
      </Typography>
      <FormattedMessage {...messages.text} />
      <Box my={'5vh'}>
      <Button onClick={handleReturnHomeClick} variant="contained">
        <FormattedMessage {...messages.home} />
      </Button>
      </Box>
    </Box>
  );
};
