import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState, forwardRef, useImperativeHandle } from "react";
import { PasswordInputProps, ValidationInputHandle } from "../types";
import TextField from "@mui/material/TextField";
import { LoginValidationServices } from "../../services/LoginValidation";
import { Password } from "../../utils/constants";
import { Box, FormHelperText } from "@mui/material";

export const PasswordInput = forwardRef<
  ValidationInputHandle,
  PasswordInputProps
>(({ label, password, setPassword, validateStrength, ...props }, ref) => {
  const restrictionMessage = `Senha deve possuir entre ${Password.MinLength} e ${Password.MaxLength} caracteres e conter símbolos (!@#$&*) e dígitos.`;

  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const validateComponent = () => {
    try {
      LoginValidationServices.validatePassword(
        password,
        validateStrength as boolean
      );

      setIsValid(true);
      setMessage("");
      return true;
    } catch (err) {
      setIsValid(false);
      setMessage((err as Error).message);
      return false;
    }
  };

  useImperativeHandle(ref, () => ({ validateComponent }));

  const textBox = (
    <TextField
      error={!isValid}
      required
      label={label}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={password}
      onChange={(e) => setPassword(e.currentTarget.value)}
      onBlur={validateComponent}
      helperText={message}
      {...props}
    />
  );

  if (!validateStrength) {
    return textBox;
  }

  return (
    <Box>
      {textBox}

      <FormHelperText id="restriction-password-text">
        {restrictionMessage}
      </FormHelperText>
    </Box>
  );
});
