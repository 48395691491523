import { Badge, Button, Typography } from "@mui/material";
import { useState } from "react";
import { InviteButtonProps } from "../types";
import { Sharing } from "../../containers/Sharing";

export const InviteButton = ({ id, itemType, permissions }: InviteButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {permissions?.share && (
        <>
        <Badge variant="dot" color="error"
         badgeContent={permissions?.hasRequestsToAnswer ? 1 : 0}>
          <Button variant="outlined" onClick={handleClickOpen}>
            <Typography>Compartilhar</Typography>
          </Button>
          </Badge>
          <Sharing open={open} setOpen={setOpen} id={id} itemType={itemType} />
        </>
      )}
    </div>
  );
};
