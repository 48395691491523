import supabase from "./supabaseClient";

export const selectUserById = async (id: string) => {
  const { data, error } = await supabase.from("User").select().eq("id", id);
  if (error) {
    console.error("[selectItemById] Error: " + error.message);
  }
  return { data };
};

export const selectUsersById = async (usersId: string[]) => {
  const { data, error } = await supabase.from("User").select().in("id", usersId);
  if (error) {
    console.error("[selectItemById] Error: " + error.message);
  }
  return { data };
};

export const selectAllUsers = async () => {
  const { data, error } = await supabase.from("User").select();
  if (error) {
    console.error("[selectItemById] Error: " + error.message);
  }
  return { data };
};

export const insertName = async (item: any) => {
  const data = item;
  return { data };
};

export const selectName = async () => {
  const data = 1;
  return { data };
};

export const selectNameById = async (id: any) => {
  const data = id;
  return { data };
};

export const selectNameByItem = async (id: any) => {
  const data = id;
  return { data };
};

export const updateName = async (item: any) => {
  const data = item;
  return { data };
};

export const deleteName = async (id: any) => {
  const data = id;
  return { data };
};