import { TextField } from "@mui/material";
import { ContentUploadOptionHandle } from "./types";
import { forwardRef, useImperativeHandle } from "react";

export const FreeText = forwardRef<ContentUploadOptionHandle, unknown>((_, ref) => {

  useImperativeHandle(ref, () => ({
    validateComponent: () => true, 
    buildContent: () => ({ })
  }));

  return (
    <TextField
      sx={{
        justifyContent: "center",
        width: "100%",
      }}
      multiline
      label="Escreva seu texto"
    />
  );
});
