import { Box, Button } from "@mui/material";
import { CardSettingsProps } from "../Card/types";
import { deleteStudy } from "../../services/dao/Study";
import { deleteYard } from "../../services/dao/Yard";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../utils/constants";

export const RemoveItem = ({
  isYard,
  id,
  closeDialog,
}: CardSettingsProps) => {

  const navigate = useNavigate();
  const handleClick = () => {
    if (isYard) {
      deleteYard(id);
    } else {
       deleteStudy(id);
    }

    closeDialog();
    navigate(Pages.Home);
  };

  return (
  <Box m={2}>
    <Box>Tem certeza que deseja remover este card?</Box>
    <Box mt={2}>
      <Button sx={{ mr: 3 }} onClick={handleClick}>
        Remover
      </Button>
      <Button onClick={closeDialog}>Cancelar</Button>
    </Box>
  </Box>
);
};
