import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import MuiAppBar from "@mui/material/AppBar";
import { Box } from "@mui/material";

import messages from "./messages";
import { LocalStorage, Pages } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { LoginServices } from "../../services/Login";
import { AppBarBreadcrumb } from "../Breadcrumb/AppBarBreadcrumb";

export const AppBar = () => {
  const loggedUser = LoginServices.getLoggedUser();

  const username = loggedUser
    ? "Olá, " + loggedUser.name
    : "Entre ou crie sua conta";

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem(LocalStorage.Login);
    if (window.location.pathname === Pages.Home) {
      window.location.reload();
    } else {
      navigate(Pages.Home);
    }
  };

  const profileIcon = (
    <Box display="flex" flexDirection="row" sx={{ color: "white" }}>
      <Typography mr={2}>
        <FormattedMessage {...messages.username} values={{ username }} />
      </Typography>
      <SettingsIcon />
    </Box>
  );

  const loggedInBreadcrumbOptions = [
    { text: "Sair", icon: <LogoutIcon />, optionClick: handleLogout },
  ];

  const generalBreadcrumbOptions = [
    {
      text: "Fazer Login",
      icon: <LoginIcon />,
      optionClick: () => navigate(Pages.Login),
    },
    {
      text: "Criar Conta",
      icon: <PersonAddAlt1Icon />,
      optionClick: () => navigate(Pages.Register),
    },
  ];

  return (
    <Box pb={7.5} display="flex">
      <MuiAppBar sx={{ backgroundColor: "#2E8976" }}>
        <Box
          my={1.5}
          px={3}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography component="h1" variant="h5">
            <a
              href="/"
              style={{
                textDecoration: "none",
                boxShadow: "none",
                color: "white",
              }}
            >
              <FormattedMessage {...messages.title} values={{ link: "/" }} />
            </a>
          </Typography>
          <AppBarBreadcrumb
            title={profileIcon}
            options={
              loggedUser ? loggedInBreadcrumbOptions : generalBreadcrumbOptions
            }
          />
        </Box>
      </MuiAppBar>
    </Box>
  );
};
