import { Button, Dialog, Grid, Typography, Box, Input, NativeSelect } from "@mui/material";
import { useState } from "react";

export const NewQuestion = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickSubmit = () => {
    const videoUrl = "https://www.youtube.com/shorts/uthRkIJHoa8"; // Defina o valor do seu parâmetro
    const url = `http://localhost:3000/obter_valor?videoUrl=${videoUrl}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => console.log(data.resultado))
      .catch((error) => console.error("Erro:", error));
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        <Typography> + Nova Questão </Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <Box
          display="flex"
          width="100%"
          maxHeight="100%"
          alignItems="center"
          flexDirection="column"
          p={2}
          pb={1}
        >
          <Box
            display="flex"
            width="100%"
            maxHeight="100%"
            alignItems="center"
            flexDirection="column"
            border={1}
            borderColor="lightgray"
            borderRadius={3}
            p={3}
            pb={2}
            m={1}
          >
            <Box width="100%" position="relative" display="flex" sx={{ backgroundColor: "white" }} pb={1}>
              <Grid container display="flex" width="100%">
                <Box display="flex" width="100%">
                  <Typography width="20%" pt="5px" pr="2%" align="right">Título:</Typography>
                  <Box display="flex" width="80%"><Input fullWidth type="text"></Input></Box>
                </Box>
                <Box display="flex" width="100%">
                  <Typography width="20%" pt="5px" pr="2%" align="right">Descrição:</Typography>
                  <Box display="flex" width="80%"><Input fullWidth type="text"></Input></Box>
                </Box>
                <Box display="flex" width="100%">
                  <Typography width="20%" pt="5px" pr="2%" align="right">Tags:</Typography>
                  <Box display="flex" width="80%"><Input fullWidth type="text"></Input></Box>
                </Box>
                <Box display="flex" width="100%">
                  <Typography width="20%" pt="5px" pr="2%" align="right">Postagem:</Typography>
                  <Box display="flex" width="80%"><Input fullWidth type="datetime-local"></Input></Box>
                </Box>
                <Box display="flex" width="100%">
                  <Typography width="20%" pt="5px" pr="2%" align="right">Visibilidade:</Typography>
                  <Box display="flex" width="80%">
                    <NativeSelect fullWidth defaultValue={"private"}>
                      <option value={"private"}>Privado</option>
                      <option value={"public"}>Público</option>
                      <option value={"restricted"}>Restrito</option>
                    </NativeSelect>
                  </Box>
                </Box>
              </Grid>
            </Box>
            <Button sx={{ borderRadius: 6, my: 2 }} variant="contained" onClick={handleClickSubmit}>
              Adicionar Questão
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};
