import { LoginServices } from "../Login";
import supabase from "./supabaseClient";
import { FileObject } from "@supabase/storage-js";

export const insertContent = async (data: any) => {
  const item = {
    visibility: data.visibility,
    creator: data.creator,
    title: data.title,
    description: data.description,
    tags: data.tags,
  };
  const { data: item_data, error: item_error } = await supabase
    .from("Item")
    .insert([item])
    .select("id");

  if (item_error) {
    console.error(item_error.message);
    const { error: delete_error } = await supabase.storage
      .from("Studyard")
      .remove([data.study + "/" + data.path]);
    delete_error && console.error(delete_error.message);
    throw new Error("Erro ao salvar dados do arquivo importado.");
  }

  const content = {
    item: item_data[0].id,
    study: data.study,
    path: data.path,
    transcription: data.transcription,
    url: data.url,
  };
  const { data: content_data, error: content_error } = await supabase
    .from("Content")
    .insert([content])
    .select("id");

  if (content_error) {
    console.error(content_error.message);
    const { error: delete_error } = await supabase.storage
      .from("Studyard")
      .remove([data.study + "/" + data.path]);
    delete_error && console.error(delete_error.message);
    throw new Error("Erro ao salvar dados do arquivo importado.");
  }

  if (content_data) {
    return data[0].id;
  } else {
    return null;
  }
};

export const handleUpload = async ({
  file,
  studyId,
  title,
  description,
  tags,
  url,
  visibility
}: any) => {
  const fileName = file?.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const loggedUser = await LoginServices.getLoggedUser();
  if (!loggedUser) {
    throw new Error("Você deve estar logado para importar um arquivo.");
  }

  if (file) {
    const { error } = await supabase.storage
      .from("Studyard")
      .upload(studyId + "/" + fileName, file);
    if (error) {
      console.error(error.message);
      throw new Error("Erro ao salvar o arquivo importado.");
    }
  }

  var formattedTags = tags ? tags.split(",") : null;
  const content = {
    visibility: visibility,
    creator: loggedUser.id,
    title,
    description,
    tags: formattedTags,
    study: studyId,
    path: fileName,
    transcription: "",
    url,
  };
  await insertContent(content);
  window.location.reload();
  return;
};

const getPublicUrl_ = (studyId: string, content: FileObject) => {
  const { data } = supabase.storage
    .from("Studyard")
    .getPublicUrl(studyId + "/" + content.name);
  return data?.publicUrl;
};

const mapFiles_ = async (studyId: string, contents: any[]) => {
  const { data, error } = await supabase.storage.from("Studyard").list(studyId);
  if (error) {
    console.error(error.message);
    throw new Error("Não foi possível recuperar informações dos conteúdos.");
  }

  for (let content of contents) {
    const file = data.find((file) => file.name === content.path);
    if (!content.url && file) {
      content.url = getPublicUrl_(studyId, file);
    }
  }
};

export const getContents = async (studyId: string) => {
  const { data, error } = await supabase
    .from("Content")
    .select("*, Item!inner(*, Sharing(*))")
    .eq("Item.active", true)
    .eq("study", studyId);

  if (error) {
    console.error(error.message);
    throw new Error("Não foi possível recuperar os conteúdos desse estudo.");
  }
  await mapFiles_(studyId, data);
  return { data };
};

export const selectContentById = async (id: any) => {
  const { data, error } = await supabase
    .from("Content")
    .select("*, Item!inner(*, Sharing(*))")
    .eq("id", id);
  if (error) {
    console.error("[selectContentById] Error: " + error.message);
  }
  return { data };
};

export const selectContentsByStudy = async (id: any) => {
  const { data, error } = await supabase
    .from("Content")
    .select("*, Item!inner(*, Sharing(*))")
    .eq("study", id)
    .eq("Item.active", true);

  if (error) {
    console.error("[selectContentsByStudy] Error: " + error.message);
  }
  return { data };
};

export const insertName = async (item: any) => {
  const data = item;
  return { data };
};

export const selectName = async () => {
  const data = 1;
  return { data };
};

export const selectNameById = async (id: any) => {
  const data = id;
  return { data };
};

export const selectNameByItem = async (id: any) => {
  const data = id;
  return { data };
};

export const updateName = async (item: any) => {
  const data = item;
  return { data };
};

export const deleteName = async (id: any) => {
  const data = id;
  return { data };
};
