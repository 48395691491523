import { LoginServices } from "../Login";
import { insertItem, updateItem } from "./Item";
import supabase from "./supabaseClient";

export const insertStudy = async (study: any) => {
  const itemId = await insertItem(study.Item);
  if (itemId !== null) {
    delete study["Item"];
    study["item"] = itemId;
    const { data, error } = await supabase
      .from("Study")
      .insert([study])
      .select("id");
    if (error) {
      console.error("[insertStudy] Error: " + error.message);
      return null;
    } else {
      return data[0].id;
    }
  } else {
    return null;
  }
};

export const insertStudies = async (studies: any) => {
  for (var i = 0; i < studies.length; i++) {
    var itemId = await insertItem(studies[i]["Item"]);
    delete studies[i]["Item"];
    if (itemId !== null) {
      studies[i]["item"] = itemId;
    }
  }

  const { data, error } = await supabase
    .from("Study")
    .insert(studies)
    .select("id");
  if (error) {
    console.error("[insertStudies] Error: " + error.message);
  }
  return { data };
};

export const selectStudies = async () => {
  const loggedUser = await LoginServices.getLoggedUser();
  const { data, error } = await supabase
    .from("Study")
    .select("*, Yard(*), Item!inner(*, Sharing(*))")
    .eq("Item.active", true);
  if (error) {
    console.error("[selectStudies] Error: " + error.message);
  }

  if (data && loggedUser){
    for (var i = data.length - 1; i >= 0; i--) {
      if (data[i]["Item"]["creator"] !== loggedUser.id) {
        if (data[i]["Item"]["visibility"] === "private") {
          delete data[i];
        }
      }
    }
  }
  return { data };
};

export const selectAllStudies = async () => {
  const loggedUser = await LoginServices.getLoggedUser();
  const { data, error } = await supabase
    .from("Study")
    .select("*, Item!inner(*, Sharing(*)), Yard(*)");
  if (error) {
    console.error("[selectAllStudies] Error: " + error.message);
  }

  if (data && loggedUser){
    for (var i = data.length - 1; i >= 0; i--) {
      if (data[i]["Item"]["creator"] !== loggedUser.id) {
        if (data[i]["Item"]["visibility"] === "private") {
          delete data[i];
        }
      }
    }
  }
  return { data };
};

export const selectStudyById = async (id: any) => {
  const loggedUser = await LoginServices.getLoggedUser();
  const { data, error } = await supabase
    .from("Study")
    .select("*, Item!inner(*, Sharing(*)), Yard(*)")
    .eq("id", id);
  if (error) {
    console.error("[selectStudyById] Error: " + error.message);
  }

  if (data && loggedUser){
    for (var i = data.length - 1; i >= 0; i--) {
      if (data[i]["Item"]["creator"] !== loggedUser.id) {
        if (data[i]["Item"]["visibility"] === "private") {
          delete data[i];
        }
      }
    }
  }
  return { data };
};

export const selectStudiesByYard = async (yard: any) => {
    const loggedUser = await LoginServices.getLoggedUser();
    const { data, error } = await supabase
      .from("Study")
      .select("*, Yard(*), Item!inner(*, Sharing(*))")
      .eq("yard", yard)
      .eq("Item.active", true);
    if (error) {
      console.error("[selectStudiesByYard] Error: " + error.message);
    }

    if (data && loggedUser){
      for (var i = data.length - 1; i >= 0; i--) {
        if (data[i]["Item"]["creator"] !== loggedUser.id) {
          if (data[i]["Item"]["visibility"] === "private") {
            delete data[i];
          }
        }
      }
    }

    return { data };
};

export const selectAllStudiesByYard = async (yard: any) => {
  const loggedUser = await LoginServices.getLoggedUser();
  const { data, error } = await supabase
    .from("Study")
    .select("*, Item!inner(*, Sharing(*)), Yard(*)")
    .eq("yard", yard);
  if (error) {
    console.error("[selectAllStudiesByYard] Error: " + error.message);
  }

  if (data && loggedUser){
    for (var i = data.length - 1; i >= 0; i--) {
      if (data[i]["Item"]["creator"] !== loggedUser.id) {
        if (data[i]["Item"]["visibility"] === "private") {
          delete data[i];
        }
      }
    }
  }
  return { data };
};

export const selectStudiesByVariable = async (variable: string, value: any) => {
  const loggedUser = await LoginServices.getLoggedUser();
  const { data, error } = await supabase
    .from("Study")
    .select("*, Item!inner(*, Sharing(*)), Yard(*)")
    .eq(variable, value)
    .eq("Item.active", true);
  if (error) {
    console.error("[selectStudiesByVariable] Error: " + error.message);
  }

  if (data && loggedUser){
    for (var i = data.length - 1; i >= 0; i--) {
      if (data[i]["Item"]["creator"] !== loggedUser.id) {
        if (data[i]["Item"]["visibility"] === "private") {
          delete data[i];
        }
      }
    }
  }
  return { data };
};

export const updateStudy = async (study: any) => {
  const { data } = await selectStudyById(study.id);

  if (!data) {
    throw new Error("Estudo não encontrado para o processo de atualização.");
  }

  const currentItem = data[0].Item;
  currentItem.title = study.Item.title;
  currentItem.description = study.Item.description;
  currentItem.tags = study.Item.tags;
  currentItem.visibility = study.Item.visibility;
  delete currentItem.Sharing;

  await updateItem(currentItem);
};

export const deleteStudy = async (id: any) => {
  const { data } = await selectStudyById(id);

  if (!data) {
    throw new Error("Estudo não pode ser removido no momento.");
  }

  const currentItem = data[0].Item;
  currentItem.active = false;
  delete currentItem.Sharing;

  return await updateItem(currentItem);
};
