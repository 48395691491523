import React, { useState } from "react";
import { Badge, Box, Popover } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CardSettingsProps } from "../Card/types";
import { CardSettingsButton } from "./CardSettingsButton";

export const CardSettings = ({
  isGenericYard,
  id,
  permissions,
}: CardSettingsProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  
  return (
    <>
      {(permissions?.edit || permissions?.share)  && (
        <div className="card-settings" style={
          permissions?.hasRequestsToAnswer ?
          {minWidth:"5vw", minHeight:"10vh"}:
          {}}>
          <Box pl={0.5} onClick={handleClick} sx={{ cursor: "pointer" }}>
            <Badge variant="dot" color="error" 
            anchorOrigin={{vertical:"bottom", horizontal:"right"}} 
            badgeContent={permissions?.hasRequestsToAnswer?1:0}>
            <MoreVertIcon />
            </Badge>
          </Box>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <CardSettingsButton
              id={id}
              isGenericYard={isGenericYard}
              permissions={permissions}
            />
          </Popover>
        </div>
      )}
    </>
  );
};
