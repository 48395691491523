import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { GoogleLoginInputProps } from "../types";
import { LoginServices } from "../../services/Login";
import { useNavigate } from "react-router-dom";

export const GoogleLoginInput = ({
  onError,
  onSuccess,
}: GoogleLoginInputProps) => {
  const navigate = useNavigate();

  const handleGoogleSucess = async (response: CredentialResponse) => {
    LoginServices.handleGoogleLoginSuccess(response)
      .then((res) =>
        res.confirmed
          ? onSuccess(res)
          : navigate("/register", { state: { user: res } })
      )
      .catch((err) => onError(err.message));
  };

  const handleGoogleError = () =>
    onError("Houve um erro ao fazer login. Tente novamente mais tarde!");

  return (
    <GoogleLogin
      shape="rectangular"
      text="continue_with"
      size="medium"
      width={"350"}
      onSuccess={handleGoogleSucess}
      onError={handleGoogleError}
    />
  );
};
