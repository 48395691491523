import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Switch, TextField, Tooltip } from "@mui/material";
import {
  insertYard,
  selectYardById,
  updateYard,
} from "../../services/dao/Yard";
import {
  insertStudy,
  selectStudyById,
  updateStudy,
} from "../../services/dao/Study";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../utils/constants";
import { InfoOutlined } from "@mui/icons-material";

interface NewCardDialogProps {
  isGenericYardPage: boolean | undefined;
  handleClose: any;
  id: number | undefined | string;
  isEditCard?: boolean;
  isYard?: boolean;
}

export const NewCardDialog = ({
  isGenericYardPage,
  handleClose,
  id,
  isEditCard = false,
  isYard = false,
}: NewCardDialogProps) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [loadedPublic, setLoadedPublic] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    let data;
    e.preventDefault();

    const formattedTag = tags ? tags.split(",") : null;

    if (!title) {
      setTitleError(true);
      if (!description) {
        setDescriptionError(true);
      }

      return;
    }

    if (!description) {
      setDescriptionError(true);
      if (!title) {
        setTitleError(true);
      }
      return;
    }

    const item = {
      title,
      description,
      tags: formattedTag,
      visibility: isPublic ? "public" : "restricted",
    };

    if (isEditCard) {
      const input = { Item: item, id };

      data = isGenericYardPage
        ? await updateYard(input)
        : await updateStudy(input);
    } else {
      const input = isGenericYardPage
        ? { Item: item }
        : { Item: item, yard: id };

      data = isGenericYardPage
        ? await insertYard(input)
        : await insertStudy(input);
    }

    if (data) {
      setTitleError(false);
      setDescriptionError(false);

      if (!isEditCard && isGenericYardPage) {
        navigate(Pages.MyYards);
      }
    }

    window.location.reload();
    handleClose();
  };

  useEffect(() => {
    const setElements = ({ data }: any) => {
      if (data && data.length) {
        setTitle(data[0].Item.title);
        setDescription(data[0].Item.description);
        setTags(data[0].Item.tags?.join(","));
        setIsPublic(data[0].Item.visibility === "public");
        setLoadedPublic(data[0].Item.visibility === "public");
      }
    };

    if (isEditCard) {
      if (isGenericYardPage) {
        selectYardById(id).then(setElements);
      } else {
        selectStudyById(id).then(setElements);
      }
    }
  }, [isEditCard, isGenericYardPage, id]);

  const newCardTitle = isGenericYardPage
    ? "Criar um Novo Yard"
    : "Criar um Novo Estudo";

  const editCardTitle = isYard ? "Editar Yard" : "Editar Estudo";

  const cardTitle = isEditCard ? editCardTitle : newCardTitle;

  return (
    <Box width={{ xs: "100%", sm: "500px" }}>
      <Box
        display="flex"
        sx={{ position: "relative", backgroundColor: "ghostwhite" }}
        justifyContent="space-between"
        p={2}
      >
        <Box display="flex" alignItems={"flex-start"} flexDirection={"column"}>
          <Typography variant="h6" pr={2}>
            {cardTitle}
          </Typography>
          {!isEditCard && isGenericYardPage && (
            <Typography pr={2} variant="caption">
              Seu novo yard poderá ser encontrado na página "Meus Yards".
            </Typography>
          )}
        </Box>
        <Box
          onClick={handleClose}
          aria-label="close"
          sx={{ cursor: "pointer" }}
        >
          <CloseIcon />
        </Box>
      </Box>
      <Box p={2}>
        <Box p={2}>
          <Typography>Título</Typography>
          <TextField
            error={titleError}
            variant="standard"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            helperText={
              titleError ? "Preencha todos os campos obrigatórios" : ""
            }
          />
        </Box>
        <Divider />
        <Box p={2}>
          <Typography>Descrição</Typography>
          <TextField
            error={descriptionError}
            variant="standard"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            helperText={
              descriptionError ? "Preencha todos os campos obrigatórios" : ""
            }
          />
        </Box>
        <Divider />
        <Box p={2}>
          <Typography>Tags</Typography>
          <TextField
            variant="standard"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </Box>
        {!isGenericYardPage && !loadedPublic && (
          <>
            <Divider />
            <Box p={2}>
              <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
              <Typography mx={1}>
                Público 
              </Typography>
              <Tooltip title="Itens públicos não poderão se tornar privados depois.">
              <InfoOutlined sx={{color:"inherit", fontSize:"inherit"}} />
              </Tooltip>
              </Box>
              <Switch
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
              />
            </Box>
          </>
        )}
        <Button variant="contained" onClick={handleSubmit}>
          Salvar
        </Button>
      </Box>
    </Box>
  );
};
