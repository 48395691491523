import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "app.components.AppBar.title",
    defaultMessage: "Studyard",
  },
  username: {
    id: "app.components.AppBar.username",
    defaultMessage: "{username}",
  },
});
