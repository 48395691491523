import Grid from "@mui/material/Grid";
import { LoginForm } from "../../containers/LoginForm/LoginForm";
import logo from "../../assets/logo.png";

export const Login = () => {
  return (
    <Grid container height={"100vh"} sx={{ backgroundColor: "#49c5b6" }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        maxHeight={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={{ xs: "center", sm: "center", md: "flex-end" }}
      >
        <img src={logo} style={{ width: 500 }} alt="fireSpot"/>
      </Grid>
      <Grid item xs={12} sm={12} md={6} px={{ xs: 0, sm: 0, md: "5vw" }}>
        <LoginForm />
      </Grid>
    </Grid>
  );
};
